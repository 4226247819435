import { DefaultTheme } from 'styled-components';

const dark: DefaultTheme = {
  title: 'dark',
  colors: {
    primary: '#45BCFF',
    text: '',
    description: '#A8a8a8',
    header_background: '#262639',
    header_top_border: '#1B1B28',
    bg_login_page: '#191924',
    bg_modules_page: '#151521',
    container_bg: '#262639',
    main_blue_clr: '#187DE4',
    title_color: '#FFFFFF',
    text_color: '#DBDEEA',
    inputBorder: '#2b2b40',
    sidebar_bg: '#262639',
    switchButtonMarkedBg: '#1C3238',
    switchButtonMarkedCircle: '#50CD89',
    switchButtonMarkedInnerCircle: '#FFFFFF',
    switchButtonUnmarkedBg: '#323248',
    switchButtonUnmarkedCircle: '#6D6D80',
    switchButtonUnmarkedInnerCircle: '#FFFFFF',
    container_title: '#EFF2F5',
    textTable: '#B5B5C3',
    table_column_title: '#4C4C66',
    sidebarLink: '#adadad',
    sidebarSelected: '#3B4146',
    table_row_line: '#4C4C66',
    sidebarSelectedLink: '#DDDDDD',
    table_bg: '#262639',
    searchBoxTitle: '#EFF2F5',
    searchBoxBg: '#262639',
    environmentTypes: {
      dev: {
        bg: '#392F28',
        color: '#F1BC00',
      },
      homol: {
        bg: '#1C3238',
        color: '#46BE7D',
      },
      prod: {
        bg: '#3A2434',
        color: '#D9214E',
      },
    },
    products: {
      adm: {
        bg: '',
        color: '',
      },
      wms: {
        bg: '',
        color: '',
      },
      inv: {
        bg: '',
        color: '',
      },
    },
    tagStatusColors: {
      em_andamento_or_inativo: {
        color: '#F1BC00',
        bg: '#392F28',
      },
      cancelado: {
        color: '#3F4254',
        bg: '#15151E',
      },
      finalizado_divergente_or_bloqueado: {
        color: '#D9214E',
        bg: '#3A2434',
      },
      finalizado_or_ativo: {
        color: '#46BE7D',
        bg: '#1C3238',
      },
      pendente: {
        color: '#B5B5C3',
        bg: '#4C4C66',
      },
      aguardando_faturamento_or_integracao_or_expedicao: {
        color: '#F69B11',
        bg: '#39272C',
      },
      andamento: {
        color: '#F1BC00',
        bg: '#392F28',
      },
      ressuprimento: {
        color: '#663259',
        bg: '#2E2237',
      },
      expedicao_finalizada_or_aguardando_conferencia: {
        color: '#4A7DFF',
        bg: '#212E48',
      },
      pedido_cancelado: {
        color: '#3F4254',
        bg: '#15151E',
      },
      criado: {
        color: '#B5B5C3',
        bg: '#6D6D80',
      },
    },
    footer_text: '#DBDEEA',
  },
};

export default dark;
