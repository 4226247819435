import { DefaultTheme } from 'styled-components';

const light: DefaultTheme = {
  title: 'light',
  colors: {
    primary: '#408CFE',
    text: '#fff',
    description: '#A8a8a8',
    header_background: '#fff',
    header_top_border: '#E1E1E1',
    bg_login_page: '#F0F0F0',
    bg_modules_page: '#EEF0F8',
    sidebar_bg: '#1F1E2C',
    container_bg: '#FFFFFF',
    main_blue_clr: '#0095E8',
    title_color: '#181C32',
    text_color: '#6C7293',
    textTable: '#505050',
    inputBorder: '#E5EAEE',
    switchButtonMarkedBg: '#E8FFF3',
    switchButtonMarkedCircle: '#50CD89',
    switchButtonMarkedInnerCircle: '#FFFFFF',
    switchButtonUnmarkedBg: '#EFF2F5',
    switchButtonUnmarkedCircle: '#6D6D80',
    switchButtonUnmarkedInnerCircle: '#FFFFFF',
    container_title: '#181c32',
    sidebarLink: '#adadad',
    sidebarSelected: '#3B4146',
    sidebarSelectedLink: '#DDDDDD',
    table_row_line: '#e5eaee',
    table_bg: '#fff',
    searchBoxTitle: '#181C32',
    searchBoxBg: '#FFFFFF',
    table_column_title: '#b5b5c3',
    environmentTypes: {
      dev: {
        bg: '#FFF5F8',
        color: '#D9214E',
      },
      homol: {
        bg: '#FFF8DD',
        color: '#F1BC00',
      },
      prod: {
        bg: '#E8FFF3',
        color: '#46BE7D',
      },
    },
    products: {
      adm: {
        bg: '',
        color: '',
      },
      wms: {
        bg: '',
        color: '',
      },
      inv: {
        bg: '',
        color: '',
      },
    },
    tagStatusColors: {
      em_andamento_or_inativo: {
        color: '#F1BC00',
        bg: '#FFF8DD',
      },
      cancelado: {
        color: '#3F4254',
        bg: '#A1A5B7',
      },
      finalizado_divergente_or_bloqueado: {
        color: '#D9214E',
        bg: '#FFF5F8',
      },
      finalizado_or_ativo: {
        color: '#46BE7D',
        bg: '#E8FFF3',
      },
      pendente: {
        color: '#B5B5C3',
        bg: '#EFF2F5',
      },
      aguardando_faturamento_or_integracao_or_expedicao: {
        color: '#F69B11',
        bg: '#FFF5E6',
      },
      andamento: {
        color: '#F1BC00',
        bg: '#FFF8DD',
      },
      ressuprimento: {
        color: '#58244B',
        bg: '#FBF1F8',
      },
      expedicao_finalizada_or_aguardando_conferencia: {
        color: '#4A7DFF',
        bg: '#F1F5FF',
      },
      pedido_cancelado: {
        color: '#3F4254',
        bg: '#A1A5B7',
      },
      criado: {
        color: '#B5B5C3',
        bg: '#EFF2F5',
      },
    },
    footer_text: '#959CB6',
  },
};

export default light;
