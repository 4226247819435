/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-alert */
import React, { useLayoutEffect, useState, useCallback } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAuth } from '../hooks/Auth';
import api from '../services/api';
import Header from '../components/Layout/Header';
import IconsBg from '../components/Layout/IconsBg';
import SignedLayout from '../components/Layout/SignedLayout';
// @ts-ignore
interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType;
  isPrivate?: boolean;
  sidebarLayout?: boolean;
}

const Routes: React.FC<RouteProps> = ({
  component: Component,
  isPrivate,
  sidebarLayout,
  ...rest
}) => {
  const { user, signOut } = useAuth();
  const date = new Date().toString();
  const { enqueueSnackbar } = useSnackbar();

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const refresh = localStorage.getItem('@pdamodules::refresh');
  const refreshNoBar = refresh?.replaceAll('\\', '');
  const refreshToken = refreshNoBar?.replace(/"/g, '');

  const reauthenticate = useCallback(async () => {
    const login = user?.data.login;
    try {
      const response = await api.post(`/Autenticacao/Refresh-Token`, {
        data: { login, refreshToken },
      });

      if (response.data.authenticated) {
        localStorage.removeItem('@pdamodules::token');
        localStorage.setItem(
          '@pdamodules::token',
          JSON.stringify(response.data.accessToken)
        );
        localStorage.setItem(
          '@pdamodules::exception',
          String(Date.parse(response.data.expiration))
        );
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (err) {
      signOut();
    }
  }, [user, refreshToken, signOut]);

  useLayoutEffect(() => {
    const exception = localStorage
      .getItem('@pdamodules::exception')
      ?.replace(/"/g, '');

    const isExpired =
      Date.parse(String(exception)) - 900000 <= Date.parse(date);
    if (isExpired && user) {
      reauthenticate();
      enqueueSnackbar('Reautenticando...', {
        variant: 'warning',
      });
    }
  }, [reauthenticate, date, isAuthenticated, user, enqueueSnackbar]);

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (isPrivate) {
          if (!user) {
            enqueueSnackbar('Você deve estar logado para usar a plataforma', {
              variant: 'error',
            });

            signOut();

            return <Redirect to={{ pathname: '/' }} />;
          }
          if (sidebarLayout) {
            return (
              <SignedLayout>
                {/* @ts-ignore */}
                <Component />
              </SignedLayout>
            );
          }
          return (
            <IconsBg isLogged>
              <Header />
              {/* @ts-ignore */}
              <Component />
            </IconsBg>
          );
        }
        return (
          <IconsBg>
            {/* @ts-ignore */}
            <Component />
          </IconsBg>
        );
      }}
    />
  );
};

export default Routes;
