import styled from 'styled-components';

export const IconsBgContainer = styled.div<{ inHome?: boolean }>`
  position: relative;
  max-width: 100vw;
  min-height: 100vh;
  background-color: ${({ theme, inHome }) =>
    inHome ? theme.colors.bg_login_page : theme.colors.bg_modules_page};
  z-index: 1;
  isolation: isolate;
  overflow: hidden;

  @media screen and (max-width: 1600px) {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const ChildrenBox = styled.div<{ isLogged: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${props => props.isLogged && '1rem'};
`;

export const IconBox = styled.div<{ isTop?: boolean }>`
  position: absolute;
  top: ${props => props.isTop && '-5px'};
  right: ${props => props.isTop && '-8px'};
  bottom: ${props => !props.isTop && '-5px'};
  left: ${props => !props.isTop && '-8px'};
  transform: ${props => !props.isTop && 'rotate(-180deg)'};
  z-index: -1;
  width: 240px;
  height: 240px;
  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }
`;
