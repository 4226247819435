/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  selectOptionsRequest,
  searchBoxRequest,
} from './api/profilePermissions.api';
import { PageContainer, Search } from '../../../app/styles';
import FixedHeader from '../../../components/Tools/Breadcrumb';
import SearchBox from '../../../components/Tools/Search';
import { IProfilePermission, ItemList } from '../../../utils/interfaces';
import LoaderPda from '../../../components/Tools/LoaderPda';
import ProfilePermissionsTable from './components/ProfilePermissionsTable';

const ProfilePermissions: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingTable, setIsShowingTable] = useState(false);
  const [systems, setSystems] = useState<ItemList[]>([]);
  const [profilePermissions, setProfilePermissions] = useState<
    IProfilePermission[]
  >([]);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };
  const { profileCode } = useParams<Record<string, string | undefined>>();
  const clientId = Number(localStorage.getItem('@pdamodules::codigoCliente'));

  const codigoPerfil = String(profileCode);

  const getSystems = useCallback(async () => {
    setIsLoading(true);

    const systemsOptions = await selectOptionsRequest(clientId, codigoPerfil);

    setSystems(systemsOptions);
    setIsLoading(false);
  }, [clientId, codigoPerfil]);

  const handleSearchBoxSubmit = useCallback(
    async (data: any) => {
      if (data.systems === '') {
        enqueueSnackbar(traductorMsg('screens.profile.msgNoProfile'), {
          variant: 'warning',
        });

        return;
      }

      setIsShowingTable(false);
      setIsLoading(true);

      await searchBoxRequest(clientId, codigoPerfil, data)
        .then(res => {
          if (!res.data.length) {
            setProfilePermissions([]);
            enqueueSnackbar(traductorMsg('screens.profile.noRecordes'), {
              variant: 'warning',
            });
            return;
          }

          setProfilePermissions(res.data);
          setIsShowingTable(true);
        })
        .catch(err => {
          setIsShowingTable(false);
          enqueueSnackbar(
            `${traductorMsg('screens.user.error')}: ${err.message}`,
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar, clientId, codigoPerfil]
  );

  useLayoutEffect(() => {
    getSystems();
  }, [getSystems]);

  return (
    <>
      <FixedHeader
        title={t('screens.profile.title')}
        subTitle={String(t('screens.profileTable.permissions'))}
      />
      <Fade in timeout={1000} unmountOnExit>
        <PageContainer>
          <Search>
            <SearchBox
              handleSubmit={data => handleSearchBoxSubmit(data)}
              inputs={[
                {
                  name: 'systems',
                  label: String(t('screens.profile.titleEdit')),
                  type: 'select',
                  options: systems,
                  isRequired: true,
                  disabled: isLoading,
                  placeholder: String(t('screens.profile.titleEdit')),
                  xl: 12,
                  lg: 12,
                  xs: 12,
                  md: 12,
                  sm: 12,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <LoaderPda />}
        </PageContainer>
      </Fade>
      {!isLoading && isShowingTable && (
        <ProfilePermissionsTable rows={profilePermissions} />
      )}
    </>
  );
};

export default ProfilePermissions;
