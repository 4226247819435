/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';

import Icon from '../../Tools/Icon';
import bgIcon from '../../../assets/svg/bgIcon.svg';
import { IconsBgContainer, ChildrenBox, IconBox } from './styled';

interface IIconsBgProps {
  isLogged?: boolean;
}

const IconsBg: React.FC<React.PropsWithChildren<IIconsBgProps>> = ({
  isLogged,
  children,
}) => {
  const { pathname } = useLocation();
  const { title } = useTheme();

  const iconColor = title === 'light' ? '#E0E0E0' : '#262639';

  return (
    <IconsBgContainer inHome={pathname === '/'}>
      <IconBox isTop>
        <Icon icon={bgIcon} color={iconColor} />
      </IconBox>
      {/* @ts-ignore */}
      <ChildrenBox isLogged={isLogged}>{children}</ChildrenBox>
      <IconBox>
        <Icon icon={bgIcon} color={iconColor} />
      </IconBox>
    </IconsBgContainer>
  );
};

export default IconsBg;
