import React, { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import ConfirmationModal from 'components/Tools/ConfirmationModal';
import { deleteUserRequest } from '../../../api/users.api';
import { IDeleteProfileModalProps } from '../../../types';

const DeleteUserModal: React.FC<IDeleteProfileModalProps> = ({
  clearCurrentUser,
  getUsers,
  handleClose,
  isOpen,
  userData,
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };

  const handleConfirmDeletion = useCallback(async () => {
    await deleteUserRequest(userData.codigoUsuario)
      .then(() => {
        enqueueSnackbar(traductorMsg('screens.userDelete.inactivatedSucces'), {
          variant: 'success',
        });

        setIsConfirmed(true);
        clearCurrentUser();
        handleClose();
        getUsers();
      })
      .catch(err => {
        enqueueSnackbar(
          `${traductorMsg('screens.user.error')}: ${err.message}`,
          {
            variant: 'error',
          }
        );
      });
  }, [userData, enqueueSnackbar, clearCurrentUser, handleClose, getUsers]);

  return (
    <ConfirmationModal
      confirm={handleConfirmDeletion}
      resetConfirm={() => {
        handleClose();
        getUsers();
      }}
      deleteType
      openModal={isOpen}
      isConfirmed={isConfirmed}
      handleCloseModal={handleClose}
      doneTitle={String(t('screens.userDelete.userInativated'))}
      warningTitle={`${t('screens.userDelete.wantToLeave')} ${
        userData.login
      } ${t('screens.userDelete.inactive')} ?`}
    />
  );
};

export default DeleteUserModal;
