/* eslint-disable consistent-return */
import axios from 'axios';
import config from '../config';

const login = localStorage.getItem('@pdamodules::login')?.replace(/"/g, '');
const refreshToken = localStorage.getItem('@pdamodules::refresh');
const access_token = localStorage.getItem('@pdamodules::accessToken');

const api = axios.create({
  baseURL: config.apiConfig.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(req => {
  const requestToken = localStorage.getItem('@pdamodules::accessToken');

  if (requestToken) {
    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${requestToken.replace(/"/g, '')}`,
    };
  }

  return req;
});

api.interceptors.response.use(
  async response => Promise.resolve(response),

  async error => {
    if (error.response.status === 401 && access_token) {
      await reAuthentication();
    }
    return Promise.reject(error);
  }
);

export default api;

async function reAuthentication() {
  return new Promise((resolve, reject) => {
    try {
      axios
        .request({
          method: 'POST',
          baseURL: `${config.apiConfig.baseUrl}Autenticacao/Refresh-Token`,
          headers: {
            Authorization: `Bearer ${access_token?.replace(/"/g, '')}`,
          },
          data: {
            login,
            refreshToken,
          },
        })
        .then(async res => {
          localStorage.setItem(
            '@pdamodules::token',
            JSON.stringify(res.data.accessToken)
          );
          return resolve(res);
        })
        .catch(err => {
          window.location.replace(
            `${
              process.env.REACT_APP_ENV === 'dev'
                ? process.env.REACT_APP_LOGOUT_DEV_URL
                : process.env.REACT_APP_LOGOUT_PROD_URL
            }`
          );

          return reject(err);
        });
    } catch (err) {
      return reject(err);
    }
  });
}
