import React from 'react';
import { useTheme } from 'styled-components';

import { useTema } from 'hooks/Theme';
import { ThemeToggleContainer } from './styles';

const ThemeToggle: React.FC = () => {
  const { title } = useTheme();
  const { toggleTheme } = useTema();

  const isChecked = title === 'dark';

  return (
    <ThemeToggleContainer onClick={toggleTheme} isChecked={isChecked}>
      <div className="circle">
        <div className="inner-circle" />
      </div>
      <div className="switch-content">
        <input
          type="checkbox"
          checked={isChecked}
          readOnly
          name="toggleTheme"
        />
      </div>
    </ThemeToggleContainer>
  );
};

export default ThemeToggle;
