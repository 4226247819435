import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSidebar } from 'hooks/Sidebar';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { SignedLayoutContainer, Main } from './styles';

const SignedLayout: React.FC = ({ children }) => {
  const { isFixed } = useSidebar();

  const [closed, setClosed] = useState(true);
  const [mobile, setMobile] = useState(false);

  const { pathname } = useLocation();

  const isOnAdminModule =
    pathname !== '/' &&
    pathname !== '/choose-client' &&
    pathname !== '/choose-module';

  const handeWindowResize = useCallback(() => {
    if (window.innerWidth <= 980) return setMobile(true);

    return setMobile(false);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handeWindowResize);

    return () => window.removeEventListener('resize', handeWindowResize);
  }, [handeWindowResize]);

  return (
    <>
      <SignedLayoutContainer closed={closed} fixed={isFixed}>
        <Header onAdmin={isOnAdminModule} />
        <Main onClick={() => mobile && setClosed(false)} id="main">
          <>{children}</>
        </Main>
      </SignedLayoutContainer>
      <Sidebar />
    </>
  );
};

export default SignedLayout;
