import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const AboutContainer = styled(IconButton)`
  position: relative;
  background: ${props =>
    props.theme.title === 'light' ? '#EEF0F8' : '#1E1E2E'};
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 27px;
  min-height: 50px;
  border-radius: 10px;
  transition: ease 0.25s;
  margin-left: 8px;

  &:hover {
    background: ${props =>
      props.theme.title === 'light' ? '#ECF8FF' : '#212E48'};
  }
`;

export const AboutList = styled.div`
  width: 200px;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background-color: ${props =>
    props.theme.title === 'light' ? '#fff' : '#262639'};
  position: absolute;
  top: 10px;
  right: -1.55rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
  &:first-child li a {
    border-radius: 12px 12px 0 0;
  }
  &:last-child li a {
    border-radius: 0 0 12px 12px;
  }
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    width: 100% !important;
  }
`;

export const MenuLinkContainer = styled.a`
  padding: 5px 2.5px;
  flex: 1;
  min-width: 200px !important;
  height: 100%;
  color: ${props => (props.theme.title === 'light' ? '#6C7293' : '#FFFFFF')};
  text-align: center;
  isolation: isolate;
  background: transparent;
  border-radius: inherit;
  transition: ease 0.25s;
  border-width: 1px 0px;
  border-style: solid;
  font-size: 12px;
  border-color: ${props =>
    props.theme.title === 'light' ? '#f7f7f7' : '#202031'};

  &:hover {
    background: ${props =>
      props.theme.title === 'light' ? '#ECF8FF' : '#202031'};
    color: #0095e8;
  }
`;
