/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { SnackbarProvider, useSnackbar, SnackbarKey } from 'notistack';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

import { AuthProvider } from './Auth';
import { ProviderTheme } from './Theme';
import { NotificationProvider } from './Notification';
import { SidebarContextProvider } from './Sidebar';

const Hooks: React.FC = ({ children }) => {
  const SnackbarCloseButton: React.FC<{ id: SnackbarKey }> = ({ id }) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <IconButton onClick={() => closeSnackbar(id)}>
        <CloseIcon style={{ fill: 'white' }} />
      </IconButton>
    );
  };

  const clientId =
    '175027483481-dopqoivea5c2jfool0ruqoo1g9b2ce9a.apps.googleusercontent.com';

  const useStyles = makeStyles(() => ({
    success: {
      backgroundColor: '#50CD89 !important',
      boxShadow: 'none !important',
    },
    warning: {
      backgroundColor: '#F1BC00 !important',
      boxShadow: 'none !important',
    },
    error: {
      backgroundColor: '#F1416C !important',
      boxShadow: 'none !important',
    },
  }));

  const classes = useStyles();

  return (
    <ProviderTheme>
      <GoogleOAuthProvider clientId={clientId}>
        {/* @ts-ignore */}
        <SnackbarProvider
          maxSnack={3}
          hideIconVariant
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transitionDuration={{ enter: 300, exit: 400 }}
          autoHideDuration={3000}
          action={key => <SnackbarCloseButton id={key} />}
          classes={{ variantSuccess: classes.success }}
        >
          <AuthProvider>
            <SidebarContextProvider>
              <NotificationProvider>{children}</NotificationProvider>
            </SidebarContextProvider>
          </AuthProvider>
        </SnackbarProvider>
      </GoogleOAuthProvider>
    </ProviderTheme>
  );
};

export default Hooks;
