import React from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import breadCrumbIcon from '../../../assets/svg/breadCrumbIcon.svg';
import { Container } from './styles';
import Icon from '../Icon';

interface FixedHeaderProps {
  title: string;
  subTitle?: string;
}

const FixedHeader: React.FC<FixedHeaderProps> = ({ title, subTitle }) => {
  const theme = useTheme();

  return (
    <Container>
      <Breadcrumbs
        separator={
          <NavigateNextIcon className="colorDescription" fontSize="small" />
        }
        aria-label="breadcrumb"
      >
        <Link className="colorDescription mainTitle" to="/choose-module">
          <Icon
            icon={breadCrumbIcon}
            color={theme.title === 'light' ? '#959CB6' : '#EEF0F8'}
          />
        </Link>
        <Typography
          style={{ lineHeight: 'none' }}
          className="colorDescription subtitle"
        >
          {title}
        </Typography>
        {!!subTitle && (
          <Typography
            style={{ lineHeight: 'none' }}
            className="colorDescription subtitle"
          >
            {subTitle}
          </Typography>
        )}
      </Breadcrumbs>
    </Container>
  );
};

export default FixedHeader;
