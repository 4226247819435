import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: 0;
    border: none;
    background: transparent;
    list-style: none;
    font-family: 'Poppins', sans-serif !important;
  }

  button {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  #root {
    min-height: 100vh;
  }

  img {
    display: block;
    user-select: none;
  }

  a {
    color: inherit;
  }

  ::-webkit-scrollbar {
    width: 0.4vw;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props =>
      props.theme.title === 'light' ? '#A1A5B7' : '#6D6D80'};
    border-radius: 10px;
  }
  /* ::-webkit-scrollbar-thumb:hover {
    background: #EEF0F8;
  } */
  ::-webkit-scrollbar-track {
    background: ${props =>
      props.theme.title === 'light' ? '#E4E5EF' : '#323248'};
    border-radius: 5px;
    /* box-shadow: inset 7px 10px 12px 0px #f0f0f0; */
  }

  .list.list-selected,
  .list.list-noselected.L1 {
    height: 400px !important;
  }
`;
