/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import Module from 'components/Tools/Module';
import api from 'services/api';
import { useAuth } from 'hooks/Auth';
import { IModule, IProfile } from 'utils/interfaces';
import { ModulesBox } from '../styles';

const Modulos: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modules, setModules] = useState<IModule[]>([]);
  const [profile, setProfile] = useState({} as IProfile);

  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const client = localStorage.getItem('@pdamodules::codigoCliente');

  const getModules = useCallback(async () => {
    const formatedData = {
      codigoCliente: client,
      codigoPerfil: profile.codigoPerfil,
      tipo: 'A',
    };

    await api
      .patch('Sistema', { ...formatedData })
      .then(res => {
        if (!res.data.length) {
          enqueueSnackbar('Não foi encontrado nenhum módulo', {
            variant: 'warning',
          });
        }

        setModules(res.data);
      })
      .catch(err => {
        if (err.response.status === 401) {
          signOut();
          history.push('/');
          enqueueSnackbar('Você não está autenticado', {
            variant: 'error',
          });
        }
        enqueueSnackbar(`Erro: ${err.message}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [enqueueSnackbar, signOut, profile]);

  const validateProfile = useCallback(async () => {
    setIsLoading(true);

    await api
      .get('Usuario/Autenticado')
      .then(res => {
        setProfile(res.data);
      })
      .catch(err => {
        if (err.response.status === 401) {
          signOut();
          return;
        }
        enqueueSnackbar(`Erro: ${err.message}`, {
          variant: 'error',
        });
      });
  }, [signOut, enqueueSnackbar, client, history]);

  useLayoutEffect(() => {
    validateProfile();
  }, [validateProfile]);

  useEffect(() => {
    if (!profile.codigoPerfil) return;
    getModules();
  }, [profile]);

  return (
    <ModulesBox>
      {isLoading ? (
        <CircularProgress color="inherit" />
      ) : modules.length ? (
        modules.map((module, key) => (
          <Module isModule data={module} key={key} />
        ))
      ) : (
        <span>Não há nada</span>
      )}
    </ModulesBox>
  );
};

export default Modulos;
