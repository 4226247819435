import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  lazy,
  Suspense,
} from 'react';
import { useHistory } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';
import { useSnackbar } from 'notistack';
import MD5 from 'crypto-js/md5';
import Base64 from 'crypto-js/enc-base64';
import { FormHandles } from '@unform/core';

import ThemeToggle from 'components/Tools/ThemeToggle';
import { Input } from 'components/Tools/Form';
import PdaLogo from 'assets/svg/PdaLogo';
import { useAuth } from 'hooks/Auth';
import LoaderPda from 'components/Tools/LoaderPda';
import {
  LoginContainer,
  LoginWrapper,
  ThemeToggleBox,
  LoginTitle,
  FormWrapper,
  SubmitButton,
} from './styles';
import { GoogleLogin } from './components/GoogleLogin';

const NewPasswordModal = lazy(() => import('./components/NewPasswordModal'));

const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { signIn, user } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [alterPassword, setAlterPassword] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const handleSubmit = useCallback(
    async (data: any) => {
      const { login, senha } = data;

      if (login === '' || senha === '') {
        if (login === '')
          formRef.current?.setFieldError('login', 'Não deixe esse campo vazio');

        if (senha === '')
          formRef.current?.setFieldError('senha', 'Não deixe esse campo vazio');

        enqueueSnackbar('Não deixe campos vazios', {
          variant: 'warning',
        });

        return;
      }
      setIsLoading(true);

      const password = Base64.stringify(MD5(senha));

      const signInData =
        senha === undefined ? { login, password: '' } : { login, password };

      await signIn(signInData)
        .then(response => {
          const storagePassword =
            localStorage.getItem('@pdamodules::password') !== 'false';

          if (response.data.alterarSenha || storagePassword) {
            setAlterPassword(true);

            return;
          }

          enqueueSnackbar('Logado com sucesso!', {
            variant: 'success',
          });

          history.push('/choose-client');
        })
        .catch(err => {
          formRef.current?.reset();

          enqueueSnackbar('Verifique as credenciais e tente novamente', {
            variant: 'error',
          });

          // eslint-disable-next-line no-console
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar, history, signIn]
  );

  const handleWindowResize = useCallback(() => {
    if (window.innerWidth <= 550) return setIsMobileDevice(true);
    return setIsMobileDevice(false);
  }, []);

  useEffect(() => {
    if (user) {
      if (localStorage.getItem('@pdamodules::password') !== 'false') {
        setAlterPassword(true);

        return;
      }
      history.push('/choose-client');
    }
  }, [user, history]);

  useEffect(() => {
    formRef.current?.getFieldRef('login').focus();

    const TabToSubmit = (event: KeyboardEvent) => {
      const isEnterOrTabPressed = event.key === 'Tab' || event.key === 'Enter';

      const login = formRef.current?.getFieldValue('login');
      const password = formRef.current?.getFieldValue('senha');

      if (isMobileDevice) {
        if (isEnterOrTabPressed) {
          if (login !== '' && password !== '') {
            formRef.current?.submitForm();
          }

          formRef.current?.getFieldRef('senha').focus();
        }
      }
    };

    document.addEventListener('keydown', TabToSubmit);

    return () => document.removeEventListener('keydown', TabToSubmit);
  }, [isMobileDevice, formRef]);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  return (
    <Suspense fallback={<LoaderPda />}>
      <LoginContainer>
        <ThemeToggleBox>
          <ThemeToggle />
        </ThemeToggleBox>
        <LoginWrapper>
          <PdaLogo />
          <FormWrapper onSubmit={handleSubmit} ref={formRef}>
            <LoginTitle>Faça login para acessar a plataforma</LoginTitle>
            <Input
              name="login"
              placeholder="Seu login"
              label="Usuário"
              autoFocus
              autoComplete="current-login"
              fullWidth
            />
            <Input
              name="senha"
              placeholder="Sua senha"
              label="Senha"
              type="password"
              autoComplete="current-senha"
              fullWidth
            />
            <SubmitButton
              type="submit"
              variant="contained"
              color="default"
              disabled={isLoading}
            >
              {isLoading ? (
                <BiLoaderAlt size={24} color="#fff" />
              ) : (
                'Fazer Login'
              )}
            </SubmitButton>
          </FormWrapper>
          <GoogleLogin submit={handleSubmit} />
        </LoginWrapper>
      </LoginContainer>
      {alterPassword && (
        <NewPasswordModal
          isOpen={alterPassword}
          handleClose={() => setAlterPassword(false)}
        />
      )}
    </Suspense>
  );
};

export default Login;
