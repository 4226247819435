/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './route';
import {
  Clients,
  Login,
  Logout,
  Modules,
  Profile,
  ProfilePermissions,
  Users,
} from '../pages';

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/choose-client" exact isPrivate component={Clients} />
      <Route path="/choose-module" exact isPrivate component={Modules} />
      <Route path="/logout" exact isPrivate component={Logout} />
      <Route
        path="/Profile"
        exact
        isPrivate
        sidebarLayout
        component={Profile}
      />
      <Route
        path="/profile/:profileCode"
        exact
        isPrivate
        sidebarLayout
        component={ProfilePermissions}
      />
      <Route path="/User" exact isPrivate sidebarLayout component={Users} />
      <Route path="*" exact isPrivate component={Clients} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
