import React, { useCallback, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../hooks/Auth';

const Logout: React.FC = () => {
  const history = useHistory();
  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = useCallback(() => {
    signOut();
    enqueueSnackbar('Saiu com sucesso', {
      variant: 'success',
    });
    history.push('/');
  }, [signOut, enqueueSnackbar, history]);

  useLayoutEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <div />;
};

export default Logout;
