export default {
  translations: {
    screens: {
      header: {
        hello: 'Olá',
        support: 'Suporte',
        help: 'Ajuda',
        about: 'Sobre',
      },
      profile: {
        title: 'Perfil',
        description: 'Descrição',
        isLeader: 'É lider?',
        titleEdit: 'Sistemas',
        switchButtonEdit: 'Somente Lider',
        editSucces: 'Perfil editado com sucesso!',
        editProfile: 'Editar perfil:',
        createProfile: 'Criar Perfil',
        newProfile: 'Novo Perfil',
        create: 'Criar',
        systemsNullablw: 'Selecione algum sistema para criar um perfil',
        createProfileSucces: 'Perfil criado com sucesso!!',
        save: 'Salvar',
        menuDescription: 'Descrição Menu',
        integrate: 'Integrar',
        saving: 'Salvando',
        permissionsSaving: 'Permissões salvas',
        msgNoProfile: 'Sem registros de perfis',
      },
      profileTable: {
        code: 'Código',
        isLeader: 'Lider',
        actions: 'Ações',
        edit: 'Editar',
        permissions: 'Permissões',
      },
      user: {
        userTitle: 'Usuários',
        profile: 'Perfil',
        name: 'Nome',
        isActive: 'Está Ativo?',
        newUser: 'Novo Usuário',
        msgNoUser: 'Sem registro de usários',
        resultFound: 'resultados(s) encontrados!',
        resetPassword: 'Resetar Senha',
        branchs: 'Filiais',
        editPassword: 'Senha redefinida com sucesso',
        error: 'Error',
        errorLoginInUse:
          'O login já está em uso. Por favor, escolha outro login.',
        errorAdminLogin: 'Usuário Admin não pode ser utilizado',
        editUser: 'Editar Usuário',
        createUser: 'Criar Usuário',
        createUserSucces: 'Usuário criado com sucesso!',
        IsNullBranch: 'Selecione algum filial para criar um usuário!',
        selected: 'Selecionado',
        selectedSystem: 'Selecione um sistema!',
        noRecordes: 'Não há registros',
      },
      userTable: {
        code: 'Código',
        login: 'Login',
        status: 'Status',
        delete: 'Deletar',
      },
      userDelete: {
        inactivatedSucces: 'Usuário inativado com sucesso!',
        userInativated: 'Usuário Inativado!',
        wantToLeave: 'Deseja deixar',
        inactive: 'inativo',
      },
    },
    components: {
      table: {
        advancedFilter: 'Filtros Avançados',
        lessFilter: 'Menos Filtros',
        loagind: 'Carregando',
        search: 'Pesquisar',
        clean: 'Limpar',
        toAdd: 'Adicionar',
        fileUpload: 'Upload de Arquivo',
        cancel: 'Cancelar',
        notFound: 'Não foi encontrado nenhum registro',
        export: 'Exportar',
        recordes: 'Registros',
        to: 'a',
        toClose: 'Fechar',
        confirm: 'Confirmar',
      },
    },
  },
};
