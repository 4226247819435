import React, { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import selectArrowIcon from '../../../assets/svg/selectArrowIcon.svg';
import Icon from '../../Tools/Icon';
import { AboutContainer } from './styles';
import AboutMenu from './AboutMenu';

const About: React.FC = () => {
  const { title } = useTheme();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleColors = useCallback((): string => {
    if (isHovering || isMenuOpen)
      return title === 'light' ? '#0095E8' : '#187DE4';

    return title === 'light' ? '#151521' : '#FFFFFF';
  }, [isHovering, title, isMenuOpen]);

  return (
    <AboutContainer
      type="button"
      onClick={e => {
        setIsMenuOpen(true);
        setAnchorEl(e.currentTarget);
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Icon icon={selectArrowIcon} color={handleColors()} />
      {isMenuOpen && (
        <AboutMenu
          anchor={anchorEl}
          handleClose={() => setIsMenuOpen(false)}
          isOpen={isMenuOpen}
        />
      )}
    </AboutContainer>
  );
};

export default About;
