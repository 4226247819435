import ptBrTranslations from './pt-BR/index';
import esTranslations from './es/index';
import enUSTranslations from './en-US/index';

export default {
  'en-US': enUSTranslations,
  'pt-BR': ptBrTranslations,
  // prettier-ignore
  'es': esTranslations,
};
