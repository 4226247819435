/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import sidebarArrow from 'assets/svg/sidebarArrow.svg';
import { useSidebar } from 'hooks/Sidebar';
import api from 'services/api';
import { getStorageValue } from 'utils/localStorage';
import { version } from '../../../../package.json';
import OptionsLanguage from '../OptionsLanguage/index';
import { SideMenu } from './styles';
import ItemLink from './ItemLink';

interface LinkProps {
  codigoSistemaMenu: number;
  codigoSistema: number;
  menu: string;
  pagina: string;
  ordem: number;
}

interface PagesProps {
  codigoSistemaMenu: number;
  codigoSistema: number;
  menu: string;
  menuEs: string;
  menuUs: string;
  pagina: string;
  ordem: number;
  initialPage?: string;
  links: LinkProps[];
  linksEs: LinkProps[];
  linksUs: LinkProps[];
}

const Sidebar: React.FC = () => {
  const { pathname } = useLocation();
  const isLanguage = String(localStorage.getItem('i18nextLng'));
  const { fixSidebar, isFixed, setIsFixedState } = useSidebar();
  const [languageCurrente, setLanguageCurrente] = useState<string>(
    String(isLanguage)
  );
  const [, setClosed] = useState(true);
  const [active, setActive] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [itemId, setItemId] = useState<number>();
  const [pages, setPages] = useState<PagesProps[]>([]);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const windowResize = useCallback(() => {
    if (window.innerWidth <= 980) {
      setClosed(false);
      setMobile(true);
      setIsFixedState(true);
      return;
    }
    setClosed(false);
    setMobile(false);
  }, []);

  const codigoCliente = getStorageValue<string>('@pdamodules::codigoCliente');
  const codigoSistema = getStorageValue<string>('@pdamodules::codigoSistema');

  useEffect(() => {
    windowResize();
    window.addEventListener('resize', windowResize);

    return () => window.removeEventListener('resize', windowResize);
  }, [windowResize, codigoSistema]);

  const openItem = (index?: number) => setItemId(index);

  const handleExpand = useCallback(() => {
    fixSidebar();
    setActive(prevState => !prevState);
  }, [fixSidebar]);

  const getMenu = useCallback(async () => {
    await api
      .get<PagesProps[]>('SistemaMenu', {
        params: {
          codigoCliente,
          codigoSistema,
        },
      })
      .then(response => {
        setPages(response.data);
      });
  }, [codigoCliente, codigoSistema]);

  const handleReOpenSidebar = useCallback(() => {
    if (!expanded) setIsHovering(true);
  }, [expanded]);

  const handleHideSidebar = useCallback(() => {
    if (isHovering && isFixed) setIsHovering(false);
  }, [isHovering, isFixed]);

  const isSelectedCheck = useCallback(
    (page?: string) => {
      let finalCheck = false;

      if (page) {
        finalCheck = page?.split('/')[2] === pathname.split('/')[2];

        return finalCheck;
      }
      return false;
    },
    [pathname]
  );

  const handleLanguageDefined = (language: string) => {
    return setLanguageCurrente(language);
  };

  const handleMenuLanguage = useCallback(
    (pagesLink: PagesProps) => {
      switch (languageCurrente) {
        case 'pt-BR':
          return pagesLink.menu;
        case 'es':
          return pagesLink.menuEs;
        case 'en-US':
          return pagesLink.menuUs;
        default:
          return pagesLink.menu;
      }
    },
    [languageCurrente]
  );

  const handleLinkLanguage = useCallback(
    (link: PagesProps) => {
      switch (languageCurrente) {
        case 'pt-BR':
          return link.links;
        case 'es':
          return link.linksEs;
        case 'en-US':
          return link.linksUs;
        default:
          return link.links;
      }
    },
    [languageCurrente]
  );

  const closeSidebarWhenClickItem = () => {
    if (mobile) {
      setExpanded(false);
      setClosed(true);
      setActive(false);
      setIsHovering(true);
      setIsFixedState(true);
    }
  };

  useEffect(() => {
    getMenu();
    isSelectedCheck();
  }, [getMenu, isSelectedCheck]);

  useEffect(() => {
    if (isHovering) return setExpanded(true);

    return setExpanded(false);
  }, [isHovering]);

  useEffect(() => {
    if (!isFixed) {
      setExpanded(true);
      setClosed(false);
    } else {
      setExpanded(false);
      setClosed(true);
    }
  }, [isFixed]);

  useEffect(() => {
    console.log({ pages });
  }, [pages]);

  return (
    <SideMenu
      active={active}
      expand={expanded}
      fixed={isFixed}
      onMouseOver={handleReOpenSidebar}
      onMouseLeave={handleHideSidebar}
      notDeskTop={window.innerWidth < 1640}
      mobile={mobile}
    >
      <div className="content">
        <div className="top">
          <div className="container">
            {!expanded ? (
              <div>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  className="minifyLogo"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 2C7.84987 2.44222 11.4095 3.18865 15 4.63644C23.5883 8.09953 32.3526 15.5755 32 32.5"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <circle cx="3.5" cy="29.6" r="3" fill="#E12325" />
                  <path
                    d="M3 19.5C6.66667 19.8333 14 22.7 14 31.5"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M4 10C10.4969 10.1705 23.3907 14.8093 22.9909 32"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            ) : (
              <div>
                <svg
                  width="100"
                  height="24"
                  viewBox="0 0 100 34"
                  className="fullLogo"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 2H13C16 2.33333 22 4.8 22 12C22 19.2 16 21.6667 13 22H3C2.72386 22 2.5 22.2239 2.5 22.5V32"
                    stroke="white"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M38 2.5H29C28.7239 2.5 28.5 2.72386 28.5 3V31C28.5 31.2761 28.7239 31.5 29 31.5H38C42.5 31.3333 51.5 28.3 51.5 17.5C51.5 6.7 42.5 3 38 2.5Z"
                    stroke="white"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M81 27.2632L68.9385 2.88622C68.7567 2.5188 68.2343 2.51425 68.0461 2.87845L53.3769 31.2705C53.205 31.6033 53.4465 32 53.8211 32H68"
                    stroke="white"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M93 2C95.8499 2.44222 99.4095 3.18865 103 4.63644C111.588 8.09953 120.353 15.5755 120 32.5"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <circle cx="91.5" cy="29.6" r="3" fill="#E12325" />
                  <path
                    d="M91 19.5C94.6667 19.8333 102 22.7 102 31.5"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M92 10C98.4969 10.1705 111.391 14.8093 110.991 32"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div className="links-list">
          {pages.map((page, index) => (
            <ItemLink
              id={index}
              select={page.pagina}
              openItem={() => openItem(index)}
              openedState={itemId || 0}
              expanded={expanded}
              key={page.ordem}
              links={handleLinkLanguage(page)}
              closed={!expanded}
              setClosed={setClosed}
              codigoSistema={page.codigoSistema}
              codigoSistemaMenu={page.codigoSistemaMenu}
              menu={handleMenuLanguage(page)}
              ordem={page.ordem}
              pagina={page.pagina}
              closeSidebarFunc={closeSidebarWhenClickItem}
            />
          ))}
        </div>
      </div>
      <div className="arrow-back" onClick={handleExpand}>
        <span className="arrow-back-span">
          <button
            type="button"
            className={`arrow-back-button ${!isFixed ? 'rotate' : ''}`}
          >
            <img src={sidebarArrow} alt="Expandir sidebar" />
          </button>
        </span>
      </div>
      <OptionsLanguage
        expanded={expanded}
        isCurrentLanguage={handleLanguageDefined}
      />
      <span className="version">{`v${version}`}</span>
    </SideMenu>
  );
};

export default Sidebar;
