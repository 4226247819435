import styled from 'styled-components';

interface ContainerProps {
  checked: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 40px;
  height: 25px;
  background: ${({ checked, theme }) =>
    checked
      ? theme.colors.switchButtonMarkedBg
      : theme.colors.switchButtonUnmarkedBg};
  transition: ease 0.5s;
  border-radius: 9px;
  position: relative;
  margin-top: 12px;
  margin-bottom: 4px;
  margin-left: 12px;
  cursor: pointer;

  .circle {
    content: '';
    position: absolute;
    top: 50%;
    left: ${props => (props.checked ? 'calc(100% - 17px)' : '-5px')};
    transition: left 0.3s;
    transform-origin: center;
    transform: translate3d(0, -50%, 0);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${({ checked, theme }) =>
      checked
        ? theme.colors.switchButtonMarkedCircle
        : theme.colors.switchButtonUnmarkedCircle};

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      width: 55%;
      height: 55%;
      background-color: ${({ checked, theme }) =>
        checked
          ? theme.colors.switchButtonMarkedInnerCircle
          : theme.colors.switchButtonUnmarkedInnerCircle};
      border-radius: 50%;
    }
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }
`;
