import styled from 'styled-components';

export const SignedLayoutContainer = styled.main<{
  closed?: boolean;
  fixed?: boolean;
}>`
  position: relative;
  overflow-x: hidden;
  display: grid;
  height: 100vh;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'nav header'
    'nav main';

  margin-left: ${props => (props.fixed ? '70px' : '218px')};

  @media screen and (max-width: 981px) {
    margin-left: 0;
  }

  #main {
    grid-area: main;
    background: ${props => props.theme.colors.bg_modules_page};
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
`;

export const Main = styled.div`
  flex: 1;
  background-color: ${props => props.theme.colors.bg_modules_page};
  width: 100%;
  min-height: calc(100vh - 70px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  grid-area: main;
`;
