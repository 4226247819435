import React, { useState } from 'react';
import { Fade } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import { useTranslation } from 'react-i18next';
import editActionsIcon from '../../../../../assets/svg/editActionsIcon.svg';
import { Table } from '../../../../../components/Tools/Table';
import { IProfilesTableProps } from '../../types';
import { IProfileProps } from '../../../../../utils/interfaces';
import { TableContainer, PageContainer } from '../../../../../app/styles';
import EditProfileModal from './EditProfileModal';

const ProfilesTable: React.FC<IProfilesTableProps> = ({
  rows,
  getProfiles,
}) => {
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<IProfileProps>(
    {} as IProfileProps
  );

  const { t } = useTranslation();

  return (
    <>
      <Fade in timeout={1000} unmountOnExit>
        <PageContainer withOutPadding>
          <TableContainer>
            <Table<IProfileProps>
              rows={rows}
              columns={[
                {
                  title: String(t('screens.profileTable.code')),
                  type: 'stringCenter',
                  orderable: true,
                  props: ['codigoPerfil'],
                },
                {
                  title: String(t('screens.profile.description')),
                  type: 'stringCenter',
                  orderable: true,
                  props: ['descricaoPerfil'],
                },
                {
                  title: String(t('screens.profileTable.isLeader')),
                  type: 'boolean',
                  orderable: true,
                  props: ['lider'],
                },
                {
                  title: String(t('screens.profileTable.actions')),
                  type: undefined,
                  props: [''],
                  renderItem: row => (
                    <div className="row-actions-button-detail">
                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          setCurrentProfile(row);
                          setIsEditProfileModalOpen(true);
                        }}
                      >
                        <img
                          src={editActionsIcon}
                          alt="editar"
                          style={{ width: '20px' }}
                        />
                        <p className="hover-item">
                          {t('screens.profileTable.edit')}
                        </p>
                      </button>
                      <Link
                        className="action"
                        to={`/profile/${row.codigoPerfil}`}
                      >
                        <DnsRoundedIcon fill="#f0f0f0" />
                        <p className="hover-item">
                          {t('screens.profileTable.permissions')}
                        </p>
                      </Link>
                    </div>
                  ),
                },
              ]}
            />
          </TableContainer>
        </PageContainer>
      </Fade>
      {isEditProfileModalOpen && (
        <EditProfileModal
          handleClose={() => setIsEditProfileModalOpen(false)}
          isOpen={isEditProfileModalOpen}
          profileData={currentProfile}
          clearCurrentProfile={() => setCurrentProfile({} as IProfileProps)}
          getProfiles={getProfiles}
        />
      )}
    </>
  );
};

export default ProfilesTable;
