/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SelectItem {
  codigo?: number | string;
  descricao?: string;
  label?: string;
  value?: number | string;
  uf?: string;
  nome?: string;
  descricaoPerfil?: string;
  codigoPerfil?: number;
  codigoSistema?: number;
  descricaoSistema?: string;
}

export const transformToSelect = (array: any) => {
  return array.map((item: SelectItem) => ({
    code: item.codigo || item.uf || item.codigoPerfil || item.codigoSistema,
    description:
      item.descricao ||
      item.nome ||
      item.descricaoPerfil ||
      item.descricaoSistema,
    label:
      item.descricao ||
      item.nome ||
      item.descricaoPerfil ||
      item.descricaoSistema,
    value: item.codigo || item.uf || item.codigoPerfil || item.codigoSistema,
  }));
};
