/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import React, { useRef, useState, useLayoutEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { SelectItem } from 'utils/toSelect';

import { createUserRequest, getAllModalOptions } from '../../api/users.api';

import { ICreateUserProps } from '../../types';
import PageMode from '../../../../../components/Tools/PageMode';
import { FilialList } from '../../../../../utils/interfaces';
import TransferList from '../../../../../components/Tools/TransferList';

const CreateClient: React.FC<ICreateUserProps> = ({
  isOpen,
  handleClose,
  getUsers,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [, setSelectAllSystemsCreate] = useState(true);

  const [profiles, setProfiles] = useState([]);
  const [filialList, setFilialList] = useState<FilialList[]>([]);
  const [supplierData, setSupplierData] = useState<SelectItem[]>([]);
  const [selectedListCreate, setSelectedListCreate] = useState<FilialList[]>(
    []
  );
  const { t } = useTranslation();
  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };
  const createModalRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();
  const clientId = Number(localStorage.getItem('@pdamodules::codigoCliente'));

  const setCreateList = useCallback((list: FilialList[]) => {
    setSelectedListCreate(list);
  }, []);

  const getModalOptions = useCallback(async () => {
    setIsLoading(true);

    const [filiaisRes, profilesRes, supplier] = await getAllModalOptions(
      clientId
    );

    setFilialList(filiaisRes);
    setProfiles(profilesRes);
    setSupplierData(supplier);

    setIsLoading(false);

    createModalRef.current?.setFieldValue('ativo', true);
  }, [clientId]);

  const formatErrorMsg = (errorMsg: string) => {
    switch (errorMsg) {
      case 'O login já está em uso. Por favor, escolha outro login.':
        return traductorMsg('screens.user.errorLoginInUse');
      case 'Usuário Admin não pode ser utilizado':
        return traductorMsg('screens.user.errorAdminLogin');
      default:
        return errorMsg;
    }
  };

  const handleCreateUser = useCallback(
    async (data: object) => {
      setIsLoading(true);

      if (!selectedListCreate.length) {
        enqueueSnackbar(traductorMsg('screens.user.IsNullBranch'), {
          variant: 'warning',
        });
        setIsLoading(false);
        return;
      }

      await createUserRequest(clientId, data, selectedListCreate)
        .then(() => {
          enqueueSnackbar(traductorMsg('screens.user.createUserSucces'), {
            variant: 'success',
          });
          setCreateList([]);
          createModalRef.current?.reset();
        })
        .catch(err => {
          const errorMsg = err.response.data.erros[0].mensagem;
          errorMsg
            ? enqueueSnackbar(formatErrorMsg(errorMsg), {
                variant: 'error',
              })
            : enqueueSnackbar(
                `${traductorMsg('screens.user.createUser')}: ${err.message}`,
                {
                  variant: 'error',
                }
              );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedListCreate, clientId, enqueueSnackbar, setCreateList]
  );

  useLayoutEffect(() => {
    getModalOptions();
  }, [getModalOptions]);

  return (
    <PageMode
      isModal
      open={isOpen}
      title={String(t('screens.user.createUser'))}
      handleClose={() => {
        handleClose();
        getUsers();
      }}
      modalRefObject={createModalRef}
      modalLoading={isLoading}
      submitButtonTitle={String(t('screens.profile.create'))}
      handleSubmit={data => handleCreateUser(data)}
      submitButtonIcon
      inputs={[
        {
          name: 'login',
          label: String(t('screens.userTable.login')),
          placeholder: String(t('screens.userTable.login')),
          isRequired: true,
          type: 'text',
          xl: 6,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 8,
        },
        {
          name: 'nome',
          label: String(t('screens.user.name')),
          placeholder: String(t('screens.user.name')),
          isRequired: true,
          type: 'text',
          xl: 6,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 8,
        },
        {
          name: 'email',
          label: 'E-mail',
          placeholder: 'E-mail',
          type: 'text',
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 8,
        },
        {
          name: 'codigoPerfil',
          label: String(t('screens.user.profile')),
          placeholder: String(t('screens.user.profile')),
          type: 'select',
          options: profiles,
          isRequired: true,
          xl: 4,
          lg: 10,
          xs: 12,
          md: 4,
          sm: 8,
        },
        {
          name: 'codigoFornecedor',
          label: 'Fornecedor',
          placeholder: 'Fornecedor',
          type: 'select',
          options: supplierData,
          xl: 4,
          lg: 10,
          xs: 12,
          md: 4,
          sm: 8,
        },
        {
          name: 'ativo',
          label: String(t('screens.user.isActive')),
          type: 'switch',
          isRequired: true,
          defaultChecked: true,
        },
      ]}
    >
      {filialList.length ? (
        <TransferList
          title={t('screens.user.branchs')}
          initialList={filialList}
          path="codigoFilialERP"
          subPath="tipo"
          setSelectedList={setCreateList}
          setStateOption={setSelectAllSystemsCreate}
        />
      ) : null}
    </PageMode>
  );
};

export default CreateClient;
