/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';

import {
  Paper,
  Popper,
  MenuList,
  MenuItem,
  Button,
  Grow,
  ClickAwayListener,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BR from 'assets/svg/brazil.svg';
import ES from 'assets/svg/spain.svg';
import US from 'assets/svg/uStates.svg';
import { useEffect } from 'react';

interface IOptionsLanguageProps {
  expanded: boolean;
  isCurrentLanguage: (prop: string) => void;
}

export const OptionsLanguage: React.FC<IOptionsLanguageProps> = ({
  expanded,
  isCurrentLanguage,
}) => {
  const [open, setOpen] = React.useState(false);

  const isLanguage = String(localStorage.getItem('i18nextLng'));

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const { i18n } = useTranslation();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleChangeLanguage(language: string) {
    i18n.changeLanguage(language);
    isCurrentLanguage(language);
    handleClose();
  }

  useEffect(() => {
    if (!expanded) {
      setOpen(false);
    }
  }, [expanded]);

  return (
    <div>
      <div className="containerTitle" style={{ margin: '10px' }}>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {expanded ? (
            <p className="titleMenuLanguage">
              {isLanguage === 'pt-BR' && (
                <>
                  <img src={BR} alt="Portugues BR" /> Português-BR
                </>
              )}
              {isLanguage === 'en-US' && (
                <>
                  <img src={US} alt="Espanhol" /> Inglês-US
                </>
              )}
              {isLanguage === 'es' && (
                <>
                  <img src={ES} alt="Espanhol" /> Espanhol-ES
                </>
              )}
            </p>
          ) : (
            <p>
              {isLanguage === 'pt-BR' && (
                <>
                  <img src={BR} alt="Portugues BR" />
                </>
              )}
              {isLanguage === 'en-US' && (
                <>
                  <img src={US} alt="English Us" />
                </>
              )}
              {isLanguage === 'es' && (
                <>
                  <img src={ES} alt="English Us" />
                </>
              )}
            </p>
          )}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          className="containerPopper"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                {/* @ts-ignore */}
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <div className="TitleLanguage">
                      <MenuItem
                        onClick={() => {
                          handleChangeLanguage('pt-BR');
                        }}
                      >
                        {expanded ? (
                          <>
                            <img src={BR} alt="Portugues BR" /> Português-BR
                          </>
                        ) : (
                          <img src={BR} alt="Portugues BR" />
                        )}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleChangeLanguage('es');
                        }}
                      >
                        {expanded ? (
                          <>
                            <img src={ES} alt="Espanhol" /> Espanhol-ES
                          </>
                        ) : (
                          <img src={ES} alt="Espanhol" />
                        )}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleChangeLanguage('en-US');
                        }}
                      >
                        {expanded ? (
                          <>
                            <img src={US} alt="English Us" /> Inglês-US
                          </>
                        ) : (
                          <img src={US} alt="English Us" />
                        )}
                      </MenuItem>
                    </div>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};
export default OptionsLanguage;
