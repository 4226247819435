/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */
import React from 'react';
import { Button } from '@material-ui/core';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import GoogleIcon from '../../../../assets/svg/googleIcon.svg';

interface GoogleLoginProps {
  submit: (data: any) => Promise<void>;
}

export const GoogleLogin = ({ submit }: GoogleLoginProps) => {
  const successWrapper = (tokenResponse: any) => {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
            Accept: 'application/json',
          },
        }
      )
      .then(res => {
        submit({ login: res.data.email.split('@')[0], senha: undefined });
      })
      .catch(err => console.log(err));
  };

  const login = useGoogleLogin({
    onSuccess: tokenResponse => successWrapper(tokenResponse),
    onError: error => console.log('Login Failed:', error),
  });

  return (
    <>
      <Button
        type="submit"
        variant="contained"
        color="default"
        onClick={() => login()}
        className="googleButton"
      >
        <img className="googleIcon" src={GoogleIcon} alt="Google Icon" />
        Login com Google
        <span className="none">hid</span>
      </Button>
    </>
  );
};
