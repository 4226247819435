/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import { FormHandles } from '@unform/core';
import { useSnackbar } from 'notistack';
import i18n from 'i18next';
import DesktopMacRoundedIcon from '@material-ui/icons/DesktopMacRounded';
import PhoneAndroidRoundedIcon from '@material-ui/icons/PhoneAndroidRounded';
import { useTranslation } from 'react-i18next';
import TransferList from '../../../../../../components/Tools/TransferList';
import { systemRequest, editProfileRequest } from '../../../api/profile.api';
import PageMode from '../../../../../../components/Tools/PageMode';
import { IEditProfileModalProps } from '../../../types';
import { ItemList } from '../../../../../../utils/interfaces';

const EditProfileModal: React.FC<IEditProfileModalProps> = ({
  isOpen,
  handleClose,
  profileData,
  getProfiles,
  clearCurrentProfile,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [, setSelectAllSystemsEdit] = useState(true);
  const [systems, setSystems] = useState<ItemList[]>([]);
  const [selectedListEdit, setSelectedListEdit] = useState<ItemList[]>([]);
  const { t } = useTranslation();
  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };

  const editModalRef = useRef<FormHandles>(null);
  const clientId = Number(localStorage.getItem('@pdamodules::codigoCliente'));

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseEditModal = useCallback(() => {
    clearCurrentProfile();
    handleClose();
    getProfiles();
  }, [handleClose, clearCurrentProfile, getProfiles]);

  const handleEditProfile = useCallback(
    async (data: object) => {
      setIsLoading(false);

      await editProfileRequest(
        clientId,
        data,
        selectedListEdit,
        profileData.codigoPerfil
      )
        .then(() => {
          enqueueSnackbar(traductorMsg('screens.profile.editSucces'), {
            variant: 'success',
          });
          handleCloseEditModal();
        })
        .catch(err => {
          enqueueSnackbar(
            `${traductorMsg('screens.user.error')}: ${err.message}`,
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      clientId,
      selectedListEdit,
      profileData.codigoPerfil,
      enqueueSnackbar,
      handleCloseEditModal,
    ]
  );

  const setEditList = useCallback((list: ItemList[]) => {
    setSelectedListEdit(list);
  }, []);

  const handleInitiateModal = useCallback(async () => {
    setIsLoading(true);

    const systemsRes = await systemRequest(clientId);

    setSystems(systemsRes.data);

    setIsLoading(false);

    setTimeout(() => {
      editModalRef.current?.setFieldValue(
        'descricao',
        profileData.descricaoPerfil
      );
      editModalRef.current?.setFieldValue('lider', profileData.lider);
    }, 1);
  }, [clientId, profileData.descricaoPerfil, profileData.lider]);

  useLayoutEffect(() => {
    handleInitiateModal();
  }, [handleInitiateModal]);

  return (
    <PageMode
      isModal
      open={isOpen}
      submitButtonIcon
      modalRefObject={editModalRef}
      modalLoading={isLoading}
      submitButtonTitle={String(t('screens.profileTable.edit'))}
      handleSubmit={data => handleEditProfile(data)}
      title={`${t('screens.profile.editProfile')} ${
        profileData.descricaoPerfil
      }`}
      handleClose={handleCloseEditModal}
      inputs={[
        {
          name: 'descricao',
          label: String(t('screens.profile.description')),
          placeholder: String(t('screens.profile.description')),
          type: 'text',
          isRequired: true,
          xl: 6,
          lg: 6,
          xs: 6,
          md: 6,
          sm: 6,
        },
        {
          name: 'lider',
          label: String(t('screens.profile.switchButtonEdit')),
          type: 'switch',
          xl: 6,
          lg: 6,
          xs: 6,
          md: 6,
          sm: 6,
        },
      ]}
    >
      {systems.length ? (
        <TransferList
          height={10}
          title={String(t('screens.profile.titleEdit'))}
          initialList={systems}
          path="descricaoSistema"
          subPath="tipo"
          setSelectedList={setEditList}
          selectedList={profileData.sistemas}
          iconListItem
          setStateOption={setSelectAllSystemsEdit}
          rendericonListItem={type => {
            return type === 'M' ? (
              <PhoneAndroidRoundedIcon />
            ) : (
              <DesktopMacRoundedIcon />
            );
          }}
        />
      ) : null}
    </PageMode>
  );
};

export default EditProfileModal;
