import React, { useCallback, useState } from 'react';
import { Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import editActionsIcon from '../../../../../assets/svg/editActionsIcon.svg';
import deleteActionsIcon from '../../../../../assets/svg/deleteActionsIcon.svg';
import { Table } from '../../../../../components/Tools/Table';
import { IUsersTableProps } from '../../types';
import { IUserProps } from '../../../../../utils/interfaces';
import { TableContainer, PageContainer } from '../../../../../app/styles';
import EditUserModal from './EditUserModal';
import TagStatus from '../../../../../components/Tools/TagStatus';
import DeleteUserModal from './DeleteUserModal';

const UsersTable: React.FC<IUsersTableProps> = ({ rows, getUsers }) => {
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUserProps>({} as IUserProps);
  const { t } = useTranslation();
  const clearCurrentUser = useCallback(() => {
    setCurrentUser({} as IUserProps);
  }, []);

  return (
    <>
      <Fade in timeout={1000} unmountOnExit>
        <PageContainer withOutPadding>
          <TableContainer>
            <Table<IUserProps>
              rows={rows}
              columns={[
                {
                  title: String(t('screens.userTable.code')),
                  type: 'stringCenter',
                  orderable: true,
                  props: ['codigoUsuario'],
                  position: 'left',
                },
                {
                  title: String(t('screens.userTable.login')),
                  type: 'stringCenter',
                  orderable: true,
                  props: ['login'],
                },
                {
                  title: String(t('screens.user.name')),
                  type: 'stringCenter',
                  orderable: true,
                  props: ['nome'],
                },
                {
                  title: 'Email',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['email'],
                },
                {
                  title: String(t('screens.userTable.status')),
                  type: 'boolean',
                  orderable: true,
                  props: ['ativo'],
                  renderItem: row => (
                    <TagStatus status={row.ativo ? 'Ativo' : 'Inativo'} />
                  ),
                },
                {
                  title: String(t('screens.profileTable.actions')),
                  type: undefined,
                  props: [''],
                  renderItem: row => (
                    <div className="row-actions-button-detail">
                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          setCurrentUser(row);
                          setIsEditUserModalOpen(true);
                        }}
                      >
                        <img
                          src={editActionsIcon}
                          alt="editar"
                          style={{ width: '20px' }}
                        />
                        <p className="hover-item">
                          {t('screens.profileTable.edit')}
                        </p>
                      </button>
                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          setCurrentUser(row);
                          setIsDeleteUserModalOpen(true);
                        }}
                      >
                        <img
                          src={deleteActionsIcon}
                          alt="editar"
                          style={{ width: '20px' }}
                        />
                        <p className="hover-item">
                          {t('screens.userTable.delete')}
                        </p>
                      </button>
                    </div>
                  ),
                },
              ]}
            />
          </TableContainer>
        </PageContainer>
      </Fade>
      {isEditUserModalOpen && (
        <EditUserModal
          handleClose={() => setIsEditUserModalOpen(false)}
          isOpen={isEditUserModalOpen}
          userData={currentUser}
          clearCurrentUser={clearCurrentUser}
          getUsers={getUsers}
        />
      )}
      {isDeleteUserModalOpen && (
        <DeleteUserModal
          clearCurrentUser={clearCurrentUser}
          getUsers={getUsers}
          handleClose={() => setIsDeleteUserModalOpen(false)}
          isOpen={isDeleteUserModalOpen}
          userData={currentUser}
        />
      )}
    </>
  );
};

export default UsersTable;
