/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useCallback, useLayoutEffect } from 'react';
import i18n from 'i18next';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import SearchBox from '../../../components/Tools/Search';
import { UsersTable, CreateUserModal } from './components';
import { PageContainer, Search } from '../../../app/styles';
import FixedHeader from '../../../components/Tools/Breadcrumb';
import { IUserProps } from '../../../utils/interfaces';
import { searchBoxRequest, getProfilesRequest } from './api/users.api';
import CreateIcon from '../../../assets/svg/createIcon';
import LoaderPda from '../../../components/Tools/LoaderPda';

const Users: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [users, setUsers] = useState<IUserProps[]>([]);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const clientId = Number(localStorage.getItem('@pdamodules::codigoCliente'));
  const getProfiles = useCallback(async () => {
    const profilesData = await getProfilesRequest(clientId);
    setProfiles(profilesData);
  }, [clientId]);

  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };

  const handleGetUsers = useCallback(
    async (data?: object) => {
      setIsLoading(true);

      await searchBoxRequest(data, clientId)
        .then(res => {
          if (!res.data.length) {
            setUsers([]);
            enqueueSnackbar(traductorMsg('screens.user.msgNoUser'), {
              variant: 'warning',
            });
            return;
          }

          setUsers(res.data);
          enqueueSnackbar(
            `${res.data.length} ${traductorMsg('screens.user.resultFound')}`,
            {
              variant: 'success',
            }
          );
        })
        .catch(err => {
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    getProfiles();
    handleGetUsers();
  }, [handleGetUsers, getProfiles]);

  return (
    <>
      <FixedHeader title={t('screens.user.userTitle')} />
      <Fade in timeout={1000} unmountOnExit>
        <PageContainer>
          <Search>
            <SearchBox
              inputs={[
                {
                  name: 'codigoPerfil',
                  label: String(t('screens.user.profile')),
                  placeholder: String(t('screens.user.profile')),
                  type: 'select',
                  options: profiles,
                  isRequired: true,
                  isDisabled: isLoading,
                  xl: 6,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'nome',
                  label: String(t('screens.user.name')),
                  placeholder: String(t('screens.user.name')),
                  isRequired: true,
                  type: 'text',
                  xl: 6,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'ativo',
                  label: String(t('screens.user.isActive')),
                  type: 'switch',
                  xl: 6,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
              ]}
              handleSubmit={data => handleGetUsers(data)}
              createButton={() => setIsCreateUserModalOpen(true)}
              createButtonContent={
                <>
                  <CreateIcon />
                  <p>{t('screens.user.newUser')}</p>
                </>
              }
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <LoaderPda />}
        </PageContainer>
      </Fade>
      {!isLoading && <UsersTable getUsers={handleGetUsers} rows={users} />}
      {isCreateUserModalOpen && (
        <CreateUserModal
          isOpen={isCreateUserModalOpen}
          handleClose={() => setIsCreateUserModalOpen(false)}
          getUsers={handleGetUsers}
        />
      )}
    </>
  );
};

export default Users;
