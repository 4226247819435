/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '../../../../services/api';
import { transformToSelect } from '../../../../utils/toSelect';
import { IProfilePermission } from '../../../../utils/interfaces';

export const selectOptionsRequest = async (
  clientId: number,
  profileCode: string
) => {
  const requestData = {
    codigoCliente: clientId,
    codigoPerfil: Number(profileCode),
    tipo: 'A',
  };

  const systems = await api.patch('/Sistema', { ...requestData });

  const formatedData = transformToSelect(systems.data);

  return formatedData;
};

export const searchBoxRequest = async (
  clientId: number,
  profileCode: string,
  data: any
) => {
  const requestData = {
    CodigoCliente: clientId,
    codigoPerfil: Number(profileCode),
    codigoSistema: data.systems,
  };

  return api.patch<IProfilePermission[]>('/PerfilAcesso', { ...requestData });
};

export const savePermissionsRequest = async (
  clientId: number,
  permissions: IProfilePermission[]
) => {
  const modifiedItemList = permissions.map(item => ({
    codigoSistemaMenu: item.codigoSistemaMenu,
    codigoSistemaPerfil: item.codigoSistemaPerfil,
    descricaoMenu: item.descricaoMenu,
    codigoMenuPai: item.codigoMenuPai,
    permissao: item.permissao,
    editar: item.editar,
    exportar: item.exportar,
    integrar: item.integrar,
  }));

  const requestData = {
    codigoCliente: clientId,
    menus: modifiedItemList,
  };

  return api.post('/PerfilAcesso', { ...requestData });
};
