/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useCallback, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getStorageValue } from 'utils/localStorage';
import PdaLogo from '../../../assets/svg/PdaLogo';
import About from '../About';
import userIcon from '../../../assets/svg/userIcon.svg';
import { useAuth } from '../../../hooks/Auth';
import { useSidebar } from '../../../hooks/Sidebar';
import {
  HeaderContainer,
  CompanyName,
  UserInfo,
  UserInteractions,
  EnvironmentType,
} from './styles';
import ThemeToggle from '../../Tools/ThemeToggle';
import SignOutMenu from './components/SignOutMenu';

interface IHeaderProps {
  onAdmin?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ onAdmin }) => {
  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { isFixed } = useSidebar();
  const history = useHistory();
  const { t } = useTranslation();
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isSignOutMenuOpen, setIsSignOutMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const login = getStorageValue<string>('@pdamodules::login')?.replace(
    /"/g,
    ''
  );
  const companyName = getStorageValue<string>('@pdamodules::cliente')?.replace(
    /"/g,
    ''
  );
  const environment_type = String(process.env.REACT_APP_ENVIRONMENTTYPE);

  const handleLogOut = useCallback(() => {
    signOut();

    enqueueSnackbar('Saiu com sucesso!', {
      variant: 'success',
    });

    history.push('/');
  }, [signOut, history, enqueueSnackbar]);

  const handeWindowResize = useCallback(() => {
    if (window.innerWidth <= 980) {
      setIsMobileDevice(true);
      return;
    }

    setIsMobileDevice(false);
  }, []);

  useEffect(() => {
    handeWindowResize();
    window.addEventListener('resize', handeWindowResize);

    return () => window.removeEventListener('resize', handeWindowResize);
  }, [handeWindowResize]);

  return (
    <HeaderContainer onAdmin={onAdmin} isSidebarOpen={isFixed && onAdmin}>
      {!isMobileDevice ? (
        <>
          {onAdmin ? <div className="invisible-block" /> : <PdaLogo />}
          <CompanyName>{companyName}</CompanyName>
        </>
      ) : null}
      <UserInteractions>
        <ThemeToggle />
        <UserInfo>
          <p>
            {t('screens.header.hello')},{' '}
            <span className="userName">{login}</span>
          </p>
          <EnvironmentType environment_type={environment_type}>
            {environment_type || 'não informado'}
          </EnvironmentType>
        </UserInfo>
        <div className="logOutBx">
          <IconButton
            id="btn"
            onClick={event => {
              setAnchorEl(event.currentTarget);
              setIsSignOutMenuOpen(prevState => !prevState);
            }}
          >
            <div className="userBg">
              <img src={userIcon} alt="User Img" />
            </div>
          </IconButton>
          {isMobileDevice && (
            <CompanyName isMobile={isMobileDevice}>{companyName}</CompanyName>
          )}
          <SignOutMenu
            isOpen={isSignOutMenuOpen}
            anchorEl={anchorEl}
            handleClose={() => setIsSignOutMenuOpen(false)}
            handleLogOut={handleLogOut}
          />
        </div>
        {!isMobileDevice && <About />}
      </UserInteractions>
    </HeaderContainer>
  );
};

export default Header;
