import styled, { css } from 'styled-components';

interface ContainerProps {
  error: boolean;
  success: boolean;
  fullWidth?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
      max-width: 342.54px;
      margin-bottom: 10px;
    `}
  .label {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    color: #b5b5c3;
    ${props =>
      props.fullWidth &&
      css`
        font-size: 12px;
      `}
  }
  .input-wrapper {
    position: relative;
    width: 100%;
    height: 38px;
    display: flex;
    /* border: 1px solid #afafaf; */
    border-radius: 5px;
    /* &:focus-within {
      box-shadow: 0 0 0 0.5px #2684ff;
      border: 0.5px solid #2684ff;
    } */

    .requiredIcon {
      position: absolute;
      top: 2.5px;
      right: 5px;
      color: #f1416c;
    }

    input {
      flex: 1;
      height: 100%;
      line-height: 64px;
      border-radius: 5px;
      background-color: transparent;
      padding: 0 8px !important;
      font-size: 13px;
      overflow: auto;
      color: ${props =>
        props.theme.title === 'light' ? '#555555' : '#B5B5C3'} !important;
      border: 1px solid ${props => props.theme.colors.inputBorder} !important;
      transition: color 0.2s ease, background-color 0.2s ease,
        border-color 0.2s ease;
      &:focus,
      &:hover {
        border-color: ${props =>
          props.title === 'light' ? '#0095e8' : '#187DE4'} !important;
      }
      ${props =>
        props.error &&
        css`
          border-color: ${!props.error ? '#2684ff' : '#e12325'};
          box-shadow: 0 0 0 0.5px ${!props.error ? '#2684ff' : '#e12325'};
        `}

      &::placeholder {
        font-size: 13px;
        line-height: 45px;
        font-weight: 400;
        color: #b5b5c3;
      }
    }

    .icon {
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }
  }

  .error {
    text-align: left;
    padding: 4px;

    p {
      font-size: 13px;
      color: #e12325;
    }
  }
`;
