import styled from 'styled-components';

export const Container = styled.div`
  z-index: inherit;

  .input-wrapper {
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.inputBorder} !important;
    border-radius: 4px;
    padding-right: 0;
    isolation: isolate;

    &:hover {
      transition: border 0.5s;
      border-color: ${props =>
        props.title === 'light' ? '#0095e8' : '#187DE4'} !important;
    }

    &:focus {
      background-color: transparent;
    }

    input {
      flex: 1;
      height: 100%;
      background: transparent;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 13px;
      color: #555555 !important;
      cursor: pointer;
      z-index: 2;

      &:hover {
        border-color: ${props =>
          props.title === 'light' ? '#0095e8' : '#187DE4'} !important;
      }

      &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      &::placeholder {
        font-size: 13px;
        line-height: 45px;
        font-weight: 400;
        color: #b0b0b0;
      }
    }
    .icon {
      width: 40px;
      height: 100%;
      position: absolute;
      right: 5px;
      display: grid;
      place-items: center;
      z-index: 1;
      cursor: pointer;
    }
  }
`;
