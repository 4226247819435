import React from 'react';
import { useTheme } from 'styled-components';

const PdaLogo: React.FC = () => {
  const { title } = useTheme();

  return (
    <svg
      width="150"
      height="40"
      viewBox="0 0 294 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2H30.1935C37.5484 2.81699 52.2581 8.86275 52.2581 26.5098C52.2581 44.1569 37.5484 50.2027 30.1935 51.0196H5.67742C5.00043 51.0196 4.45161 51.5684 4.45161 52.2451V75.5294"
        stroke={title === 'light' ? '#000' : '#FEFEFE'}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M91.4839 3.22549H69.4193C68.7425 3.22549 68.1935 3.77417 68.1935 4.45098V73.0784C68.1935 73.7552 68.7425 74.3039 69.4193 74.3039H91.4839C102.516 73.8953 124.581 66.4608 124.581 39.9902C124.581 13.5196 102.516 4.45098 91.4839 3.22549Z"
        stroke={title === 'light' ? '#000' : '#FEFEFE'}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M196.903 63.9196L167.333 4.1721C166.887 3.27156 165.607 3.26041 165.145 4.15305L129.182 73.7414C128.761 74.5571 129.353 75.5294 130.271 75.5294H165.032"
        stroke={title === 'light' ? '#000' : '#FEFEFE'}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M226.323 2C233.309 3.08387 242.036 4.91336 250.839 8.46186C271.893 16.9498 293.382 35.2733 292.516 76.7549"
        stroke="#E12325"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M222.645 77C226.707 77 230 73.708 230 69.6471C230 65.5862 226.707 62.2941 222.645 62.2941C218.583 62.2941 215.29 65.5862 215.29 69.6471C215.29 73.708 218.583 77 222.645 77Z"
        fill="#E12325"
      />
      <path
        d="M221.419 44.8922C230.409 45.7091 248.387 52.7353 248.387 74.3039"
        stroke="#E12325"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M223.871 21.6078C239.799 22.0257 271.41 33.3953 270.43 75.5294"
        stroke="#E12325"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PdaLogo;
