/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import SearchBox from '../../../components/Tools/Search';
import { ProfilesTable, CreateProfileModal } from './components';
import { PageContainer, Search } from '../../../app/styles';
import FixedHeader from '../../../components/Tools/Breadcrumb';
import { IProfileProps } from '../../../utils/interfaces';
import { searchBoxRequest } from './api/profile.api';
import CreateIcon from '../../../assets/svg/createIcon';
import LoaderPda from '../../../components/Tools/LoaderPda';

const Profile: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState<IProfileProps[]>([]);
  const [isCreateProfileModalOpen, setIsCreateProfileModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const clientId = Number(localStorage.getItem('@pdamodules::codigoCliente'));

  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };

  const handleGetProfiles = useCallback(
    async (data?: object) => {
      setIsLoading(true);

      await searchBoxRequest(data, clientId)
        .then(res => {
          if (!res.data.length) {
            setProfiles([]);
            enqueueSnackbar(traductorMsg('screens.profile.msgNoProfile'), {
              variant: 'warning',
            });
            return;
          }

          setProfiles(res.data);
          enqueueSnackbar(
            `${res.data.length} ${traductorMsg('screens.user.resultFound')}`,
            {
              variant: 'success',
            }
          );
        })
        .catch(err => {
          enqueueSnackbar(
            `${traductorMsg('screens.user.error')}: ${err.message}`,
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    handleGetProfiles();
  }, [handleGetProfiles]);

  return (
    <>
      <FixedHeader title={t('screens.profile.title')} />
      <Fade in timeout={1000} unmountOnExit>
        <PageContainer>
          <Search>
            <SearchBox
              inputs={[
                {
                  name: 'descricaoPerfil',
                  label: String(t('screens.profile.description')),
                  type: 'text',
                  placeholder: String(t('screens.profile.description')),
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 9,
                  sm: 9,
                },
                {
                  name: 'lider',
                  label: String(t('screens.profile.isLeader')),
                  type: 'switch',
                },
              ]}
              handleSubmit={data => handleGetProfiles(data)}
              createButton={() => setIsCreateProfileModalOpen(true)}
              createButtonContent={
                <>
                  <CreateIcon />
                  <p>{t('screens.profile.newProfile')}</p>
                </>
              }
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <LoaderPda />}
        </PageContainer>
      </Fade>
      {!isLoading && (
        <ProfilesTable getProfiles={handleGetProfiles} rows={profiles} />
      )}
      {isCreateProfileModalOpen && (
        <CreateProfileModal
          isOpen={isCreateProfileModalOpen}
          handleClose={() => setIsCreateProfileModalOpen(false)}
          getProfiles={handleGetProfiles}
        />
      )}
    </>
  );
};

export default Profile;
