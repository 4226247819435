import React from 'react';

import Modulos from './components';
import { Container, Wrapper } from '../../app/styles';

const Modules: React.FC = () => (
  <Container>
    <Wrapper>
      <h2>Escolha um módulo</h2>
      <Modulos />
    </Wrapper>
  </Container>
);

export default Modules;
