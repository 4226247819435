/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import Hooks from './hooks';
import Routes from './routes';
import GlobalStyles from './styles/global';

const App: React.FC = () => (
  <Hooks>
    <GlobalStyles />
    <Routes />
  </Hooks>
);

export default App;
