import React from 'react';

import Clientes from './components';
import { Container, Wrapper } from '../../app/styles';

const Clients: React.FC = () => (
  <Container>
    <Wrapper>
      <h2>Escolha um cliente</h2>
      <Clientes />
    </Wrapper>
  </Container>
);

export default Clients;
