/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';

import { CardContainer } from '../../../app/styles';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import { IClient } from '../../../utils/interfaces';
import { ClientsBox } from '../styles';
import { IAuthResponse, ICliente } from '../types';

const Clientes: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useAuth();

  const getClients = useCallback(async () => {
    setIsLoading(true);

    await api
      .get('Usuario/Autenticado')
      .then(res => {
        if (res.data?.clientes.length > 1) {
          setClients(res.data.clientes);

          return;
        }

        const { descricaoCliente, codigoCliente } = res.data.clientes[0];

        if (codigoCliente) {
          localStorage.removeItem('@pdamodules::codigoCliente');
          localStorage.removeItem('@pdamodules::cliente');

          localStorage.setItem(
            '@pdamodules::codigoCliente',
            JSON.stringify(codigoCliente)
          );
          localStorage.setItem(
            '@pdamodules::cliente',
            JSON.stringify(descricaoCliente)
          );
          history.push('/choose-module');
        }
      })
      .catch(err => {
        if (err.response?.status === 401) {
          signOut();
          return;
        }
        enqueueSnackbar(`Erro: ${err.message}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [history, signOut, enqueueSnackbar]);

  const handleClientClick = async (client: IClient) => {
    if (client.codigoCliente) {
      setIsLoading(true);

      await api
        .get(
          `Autenticacao/Administrativo?CodigoCliente=${client.codigoCliente}`
        )
        .then(async res => {
          if (res.data.accessToken) {
            const response: IAuthResponse = res.data;

            await api
              .get(`Autenticacao?refreshToken=${response.refreshToken}`, {
                headers: {
                  Authorization: `Bearer ${response.accessToken}`,
                },
              })
              .then(async responseToken => {
                const updatedAccessToken = await responseToken.data.accessToken;

                localStorage.removeItem('@pdamodules::accessToken');
                localStorage.setItem(
                  '@pdamodules::accessToken',
                  JSON.stringify(updatedAccessToken)
                );

                await api.get('Usuario/Autenticado').then(responseAuth => {
                  const { descricaoCliente, codigoCliente, codigoSistema } =
                    responseAuth.data.clientes.find(
                      (c: ICliente) => c.codigoCliente === client.codigoCliente
                    );

                  if (codigoCliente) {
                    localStorage.setItem(
                      '@pdamodules::codigoCliente',
                      JSON.stringify(codigoCliente)
                    );
                    localStorage.setItem(
                      '@pdamodules::cliente',
                      JSON.stringify(descricaoCliente)
                    );
                    localStorage.setItem(
                      '@pdamodules::codigoSistema',
                      JSON.stringify(codigoSistema)
                    );
                    history.push('/choose-module');
                  }
                });
              });
          }
        })
        .catch(err => {
          if (err.response?.status === 401) {
            signOut();
            return;
          }
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useLayoutEffect(() => {
    getClients();
  }, [getClients]);

  return (
    <ClientsBox>
      {isLoading ? (
        <CircularProgress color="inherit" />
      ) : clients.length ? (
        clients.map((client, index) => (
          <CardContainer key={index} onClick={() => handleClientClick(client)}>
            <div className="iconBx" />
            <span className="text">{client.descricaoCliente}</span>
          </CardContainer>
        ))
      ) : (
        <span>Não há nada</span>
      )}
    </ClientsBox>
  );
};

export default Clientes;
