/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import WarningIcon from '../../../assets/svg/warningIcon.svg';
import ConfirmedIcon from '../../../assets/svg/confirmedIcon.svg';
import Modal from '../_Modal';

interface IConfirmationModalProps {
  deleteType?: boolean;
  resetConfirm: () => Promise<void> | void;
  confirm: () => Promise<void> | Promise<any>;
  isConfirmed: boolean;
  handleCloseModal: () => void;
  openModal: boolean;
  warningTitle: string;
  doneTitle: string;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  deleteType,
  resetConfirm,
  confirm,
  isConfirmed,
  handleCloseModal,
  openModal,
  warningTitle,
  doneTitle,
}) => {
  const animate = true;
  const { t } = useTranslation();
  return (
    <Modal type="confirmation" open={openModal}>
      {isConfirmed ? (
        <Fade in={animate} timeout={3000}>
          <>
            <img
              src={ConfirmedIcon}
              alt="confirmed"
              style={{ paddingTop: '53px' }}
            />
            <p>{doneTitle}</p>
            <button
              type="button"
              className="closeButton"
              onClick={resetConfirm}
            >
              {t('components.table.toClose')}
            </button>
          </>
        </Fade>
      ) : (
        <>
          {deleteType ? (
            <>
              <img src={WarningIcon} alt="warning" />
              <p>{warningTitle}</p>
              <div style={{ gap: '10px' }}>
                <button
                  type="button"
                  className="confirmButtonDelete"
                  onClick={confirm}
                >
                  {t('components.table.confirm')}
                </button>
                <button
                  type="button"
                  className="cancelButtonDelete"
                  onClick={() => handleCloseModal()}
                >
                  {t('components.table.cancel')}
                </button>
              </div>
            </>
          ) : (
            <>
              <img src={WarningIcon} alt="warning" />
              <p>{warningTitle}</p>
              <div style={{ gap: '10px' }}>
                <button
                  type="button"
                  className="confirmButton"
                  onClick={confirm}
                >
                  {t('components.table.confirm')}
                </button>
                <button
                  type="button"
                  className="cancelButton"
                  onClick={() => handleCloseModal()}
                >
                  {t('components.table.cancel')}
                </button>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
