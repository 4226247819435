type PDAModules =
  | '@pdamodules::cliente'
  | '@pdamodules::codigoCliente'
  | '@pdamodules::login'
  | '@pdamodules::refresh'
  | '@pdamodules::codigoUsuario'
  | '@pdamodules::exception'
  | '@pdamodules::authenticated'
  | '@pdamodules::password'
  | '@pdamodules::accessToken'
  | '@pdamodules::user'
  | '@pdamodules::codigoSistema';

export const getStorageValue = <T = unknown>(
  module: PDAModules,
  parseToJson?: boolean
): T | string => {
  const storageValue = localStorage.getItem(module);

  if (parseToJson) return JSON.parse(String(storageValue)) as T;

  return storageValue || '';
};

export const setStorageValue = <T>(key: PDAModules, value: T): void => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageItem = (module: PDAModules): void => {
  return localStorage.removeItem(module);
};
