/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Box,
  Popper,
  ClickAwayListener,
  Fade,
  Button,
} from '@material-ui/core';

import { BoxOptions } from '../styles';
import { ArrowLogout } from './icons';

interface ISignOutMenuProps {
  isOpen: boolean;
  handleClose(): void;
  handleLogOut(): void;
  anchorEl: any;
}

const SignOutMenu: React.FC<ISignOutMenuProps> = ({
  isOpen,
  handleClose,
  anchorEl,
  handleLogOut,
}) => {
  return (
    <>
      {/* @ts-ignore */}
      <Box className="popper-box">
        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          transition
          className="popper"
          style={{ zIndex: 999999 }}
          placement="bottom"
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Fade in timeout={1000}>
              <BoxOptions>
                <Button className="logoutBtn" onClick={handleLogOut}>
                  <p>Sair</p>
                  <ArrowLogout />
                </Button>
              </BoxOptions>
            </Fade>
          </ClickAwayListener>
        </Popper>
      </Box>
    </>
  );
};

export default SignOutMenu;
