/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../../../../services/api';
import { IProfileProps, ItemList } from '../../../../utils/interfaces';

export const searchBoxRequest = async (
  data: object | undefined,
  clientId: number
) => {
  const requestData = {
    CodigoCliente: clientId,
    ...data,
  };

  return api.patch<IProfileProps[]>('/Perfil', { ...requestData });
};

export const systemRequest = async (clientId: number) => {
  const requestData = {
    codigoCliente: clientId,
  };
  return api.patch('/Sistema', { ...requestData });
};

export const createProfileRequest = async (
  clientId: number,
  formData: any,
  systems: ItemList[]
) => {
  const requestData = {
    codigoCliente: clientId,
    lider: formData.lider,
    DescricaoPerfil: formData.descricaoCreate,
    sistemas: systems.map(item => ({
      codigoSistema: item.codigoSistema,
    })),
  };

  return api.post('/Perfil', { ...requestData });
};

export const editProfileRequest = async (
  clientId: number,
  formData: any,
  systems: ItemList[],
  codigoPerfil: number
) => {
  const requestData = {
    codigoCliente: clientId,
    lider: formData.lider,
    DescricaoPerfil: formData.descricao,
    codigoPerfil,
    sistemas: systems.map(item => ({
      codigoSistema: item.codigoSistema,
    })),
  };

  return api.put('/Perfil', { ...requestData });
};
