import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    transform: scale(0.95);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const CardContainer = styled.button<{ isModule?: boolean }>`
  position: relative;
  flex: 1;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: ${props => props.theme.colors.footer_text};

  .iconBx {
    position: relative;
    width: 100%;
    max-width: 150px;
    min-height: 150px;
    border: 2px dashed ${props => props.theme.colors.footer_text};
    border-radius: 6px;
    display: grid;
    place-items: center;
    background-color: transparent;
    transition: 0.25s ease-in-out;

    svg {
      transition: 0.25s ease-in-out;
      path {
        transition: 0.25s ease-in-out;
        fill: ${props => props.theme.colors.text_color} !important;
      }
    }

    ${props =>
      !props.isModule &&
      css`
        &::after {
          content: '';
          transition: 0.25s ease-in-out;

          width: 65%;
          height: 65%;
          border: 5px solid ${props.theme.colors.footer_text};
          border-radius: 5px;
        }
      `}
  }

  .text {
    text-align: center;
    font-weight: 500;
    font-size: clamp(1.24rem, 22px, 5vmin);
    color: inherit;
    transition: 0.05s ease-in-out;
  }

  &:hover {
    transform: scale(1.05);
    color: ${props => props.theme.colors.main_blue_clr};
    svg {
      path {
        fill: ${props => props.theme.colors.main_blue_clr} !important;
      }
    }
    .iconBx {
      border: 2px dashed ${props => props.theme.colors.main_blue_clr};
      background-color: ${props =>
        props.theme.title === 'light' ? '#ECF8FF' : '#212E48'};
      &::after {
        border: 5px solid ${props => props.theme.colors.main_blue_clr};
      }
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 220px;
    .iconBx {
      width: 125px;
      height: 125px;
      min-height: 0;
    }
  }
`;

export const Container = styled.section`
  width: 95%;
  max-width: 1820px;
  background-color: ${props => props.theme.colors.container_bg};
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  max-height: 675px;
  padding: 2rem 1rem;
  display: grid;
  place-items: center;
  margin: 0 1rem !important;
  animation: ${fadeIn} 0.25s forwards ease;
  transition: all ease 0.25s;

  @media screen and (max-width: 600px) {
    height: fit-content;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;

  h2 {
    font-weight: 500;
    color: ${props => props.theme.colors.title_color};
    font-size: clamp(1.5rem, 25px, 5vmin);
    text-align: center;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
  background-color: ${props => props.theme.colors.searchBoxBg};
`;

export const FilterButtons = styled.div`
  max-width: 100%;
  min-width: 100%;
  padding: 0 15px 15px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;

  button {
    text-transform: unset !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 25px;
    border-radius: 6px;
    box-shadow: none;
    p {
      margin-left: 9px;
    }
    &:hover {
      transition: 0.5s;
      box-shadow: none;
    }
  }
  .advancedFilterButton {
    color: ${props => (props.theme.title === 'light' ? '#46be7d' : '#04aa77')};
    background-color: ${props =>
      props.theme.title === 'light' ? '#e8fff3' : '#1C3238'};
    padding: 13px 16px;
    &:hover {
      background-color: #50cd89;
      color: #fff;
      path {
        fill: #e8fff3;
      }
    }
  }
  .closeFilterButton {
    color: ${props => (props.theme.title === 'light' ? '#f69b11' : '#EE9D01')};
    background-color: ${props =>
      props.theme.title === 'light' ? '#fff5e6' : '#392F28'};
    padding: 13px 16px;
    &:hover {
      background-color: #ee9d01;
      color: #fff5e6;
      path {
        fill: #fff5e6;
      }
    }
  }
  .clearButton {
    background-color: ${props =>
      props.theme.colors.tagStatusColors.finalizado_divergente_or_bloqueado.bg};
    color: ${props =>
      props.theme.colors.tagStatusColors.finalizado_divergente_or_bloqueado
        .color};
    padding: 13px 16px;
    &:hover {
      background-color: #f1416c;
      color: #fff5f8;
      path {
        fill: #fff5f8;
      }
    }
  }
  .searchButton {
    background-color: ${props =>
      props.theme.title === 'light' ? '#0095E8' : '#187DE4'};
    color: #fff;
    padding: 13px 16px;
    &:hover {
      background-color: ${props =>
        props.theme.title === 'light' ? '#3fbaff' : '#0095E8'};
      color: #fff;
    }
    &:disabled {
      background-color: #ecf8ff;
    }
  }
  .popUp {
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    gap: 8px;
    flex-direction: column;
    padding: 8px;
    & > .MuiButtonBase-root {
      width: 100%;
    }
  }
`;

export const PageContainer = styled.div<{ withOutPadding?: boolean }>`
  position: relative;
  width: 100%;
  grid-area: main;
  padding: ${props =>
    !props.withOutPadding ? '2.5rem 2rem 0 2rem' : '0 2rem 2rem 2rem'};

  @media screen and (max-width: 980px) {
    padding: ${props =>
      !props.withOutPadding ? '1.5rem 10px 0 10px' : '0 10px 10px 10px'};
  }
`;

export const TableContainer = styled.div<{ inPopUp?: boolean }>`
  padding: ${props => (props.inPopUp ? '3px 1rem' : '1rem')};
  color: ${props => props.theme.colors.textTable};
  background-color: ${props => props.theme.colors.table_bg};
  max-width: 100%;

  .MuiPaper-root
    .MuiAppBar-root
    .MuiAppBar-positionStatic
    .MuiAppBar-colorPrimary
    .MuiPaper-elevation4,
  .MuiAppBar-colorPrimary {
    background-color: ${props => props.theme.colors.table_bg} !important;
    border-radius: 0px !important;
  }

  & > * {
    width: 100%;
    .actionsContainer {
      gap: 10px;
      padding-top: 24px;
      .MuiIconButton-root {
        padding: 10px !important;
        /* Update colors in light.ts and dark.ts */
        background-color: #f2f2f2 !important;
      }

      .MuiButtonBase-root {
        .MuiIconButton-root {
          padding: 10px !important;
          background-color: #f2f2f2 !important;
          border-radius: none !important;
        }
      }
    }
    .beforeExport {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-right: 17px;
      margin-bottom: 0.7rem;
      .select {
        width: 37%;
      }
      button {
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.text};
      }
    }

    .MuiTabs-flexContainer {
      background-color: ${props => props.theme.colors.table_bg};

      .MuiButtonBase-root {
        background-color: ${'#ECF8FF'};
        color: ${'#0095E8'};
        border-radius: 6px;
        margin-left: 5px;
        border: none;

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .render-number,
    .render-string {
      font-weight: 400;
      line-height: 18px;
      font-size: 13px;
      font-family: 'Poppins', sans-serif;
      color: ${props => props.theme.colors.textTable};
      padding: 0 8px;
    }

    .render-number {
      text-align: right;
      padding: 0 8px;
    }

    .detailTableWrap {
      display: flex;
      align-items: center;
      margin-bottom: 0.7rem;

      p {
        margin-left: 0.7rem;
      }
    }
  }
`;

export const Search = styled.div`
  width: 100%;
  margin: 0;
  background: ${props => props.theme.colors.container_bg};
  border-radius: 5px;
  box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
`;

export const ExportButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  button {
    background-color: ${props =>
      props.theme.title === 'dark' ? '#212E48' : '#ecf8ff'};
    border-radius: 6px;
    padding: 8px 16px;
    text-transform: unset !important;
    &:hover {
      background-color: #3fbaff;
      transition: 0.5s;
      box-shadow: none;
      p {
        color: #ffffff !important;
      }
      path {
        fill: #ecf8ff;
      }
    }
  }
  p {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    color: #0095e8;
    margin-left: 8px;
  }
`;

interface IEditStatusButtonContainerProps {
  buttonBackgroundColor?: string;
  buttonTitleColor?: string;
  buttonHoverColor?: string;
  buttonHoverTitleColor?: string;
}

export const EditStatusButtonContainer = styled.div<IEditStatusButtonContainerProps>`
  button {
    background-color: ${props =>
      props.theme.title === 'dark' ? '#212E48' : '#ecf8ff'};
    border-radius: 6px;
    padding: 8px 16px;
    text-transform: unset !important;
    gap: 8px;
    &:hover {
      transition: 0.5s;
      box-shadow: none;
      background-color: ${props => props.buttonHoverColor || '#3fbaff'};
      path {
        fill: ${props => props.buttonHoverTitleColor || '#ecf8ff'};
      }
      p {
        color: ${props => props.buttonHoverTitleColor || '#ecf8ff'};
      }
    }
    p {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 12px;
      margin-left: 10px;
      color: ${props => props.buttonTitleColor || '#0095e8'};
    }
  }
`;
