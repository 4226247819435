import styled from 'styled-components';

export const ModulesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  isolation: isolate;

  h2 {
    font-weight: 500;
    color: ${props => props.theme.colors.title_color};
    font-size: clamp(1.5rem, 25px, 5vmin);
    text-align: center;
  }
`;

export const ModulesBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  flex-wrap: wrap;
  isolation: isolate;
  color: ${props => props.theme.colors.footer_text};

  @media screen and (max-width: 600px) {
    justify-content: center;
    gap: 12px;
  }
`;
