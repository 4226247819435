/* eslint-disable prettier/prettier */
import React, { useRef, useState, useLayoutEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import i18n from 'i18next';
import DesktopMacRoundedIcon from '@material-ui/icons/DesktopMacRounded';
import PhoneAndroidRoundedIcon from '@material-ui/icons/PhoneAndroidRounded';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TransferList from '../../../../../components/Tools/TransferList';
import { systemRequest, createProfileRequest } from '../../api/profile.api';
import { ICreateProfileModalProps } from '../../types';
import PageMode from '../../../../../components/Tools/PageMode';
import { ItemList } from '../../../../../utils/interfaces';

const CreateProfileModal: React.FC<ICreateProfileModalProps> = ({
  isOpen,
  handleClose,
  getProfiles,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [systems, setSystems] = useState<ItemList[]>([]);
  const [, setSelectAllSystemsCreate] = useState(true);
  const [systemsCreate, setSystemsCreate] = useState<ItemList[]>([]);
  const [selectedListCreate, setSelectedListCreate] = useState<ItemList[]>([]);
  const { t } = useTranslation();
  const createModalRef = useRef<FormHandles>(null);
  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };
  const { enqueueSnackbar } = useSnackbar();
  const clientId = Number(localStorage.getItem('@pdamodules::codigoCliente'));

  const setCreateList = useCallback((list: ItemList[]) => {
    setSelectedListCreate(list);
  }, []);

  const getSystems = useCallback(async () => {
    setIsLoading(true);
    await systemRequest(clientId)
      .then(res => {
        setSystems(res.data);
        setSystemsCreate(res.data);
      })
      .finally(() => setIsLoading(false));
  }, [clientId]);

  const handleCreateProfile = useCallback(
    async (data: object) => {
      setIsLoading(true);

      if (!selectedListCreate.length) {
        enqueueSnackbar(traductorMsg('screens.profile.systemsNullablw'), {
          variant: 'warning',
        });

        return;
      }

      await createProfileRequest(clientId, data, selectedListCreate)
        .then(() => {
          enqueueSnackbar(traductorMsg('screens.profile.createProfileSucces'), {
            variant: 'success',
          });
          setCreateList([]);
          createModalRef.current?.reset();
        })
        .catch(err => {
          enqueueSnackbar(
            `${traductorMsg('screens.user.error')}: ${err.message}`,
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [selectedListCreate, clientId, enqueueSnackbar, setCreateList]
  );

  useLayoutEffect(() => {
    getSystems();
  }, [getSystems]);

  return (
    <PageMode
      isModal
      open={isOpen}
      title={String(t('screens.profile.createProfile'))}
      handleClose={() => {
        handleClose();
        getProfiles();
      }}
      modalRefObject={createModalRef}
      modalLoading={isLoading}
      submitButtonTitle={String(t('screens.profile.create'))}
      handleSubmit={data => handleCreateProfile(data)}
      submitButtonIcon
      inputs={[
        {
          name: 'descricaoCreate',
          label: String(t('screens.profile.description')),
          placeholder: String(t('screens.profile.description')),
          type: 'text',
          isRequired: true,
          xl: 6,
        },
        {
          name: 'lider',
          label: String(t('screens.profile.switchButtonEdit')),
          type: 'switch',
          xl: 6,
        },
      ]}
    >
      {systems.length ? (
        <TransferList
          subPath="tipo"
          title={String(t('screens.profile.titleEdit'))}
          initialList={systemsCreate}
          path="descricaoSistema"
          setSelectedList={setCreateList}
          setStateOption={setSelectAllSystemsCreate}
          iconListItem
          rendericonListItem={type => {
            return type === 'M' ? (
              <PhoneAndroidRoundedIcon />
            ) : (
              <DesktopMacRoundedIcon />
            );
          }}
        />
      ) : null}
    </PageMode>
  );
};

export default CreateProfileModal;
