import styled, { css } from 'styled-components';

export const HeaderContainer = styled.header<{
  onAdmin?: boolean;
  isSidebarOpen?: boolean;
}>`
  transition-delay: 0.2s;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 75px;
  padding: 0 1rem;
  background: ${props => props.theme.colors.header_background};
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.07);
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  display: flex;
  ${props =>
    !props.onAdmin
      ? css`
          position: fixed;
          top: 0;
          left: 0;
        `
      : css`
          grid-area: header;
          position: relative;
        `}

  .invisible-block {
    visibility: hidden;
    opacity: 0;
  }

  .userInteractions {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  @media screen and (max-width: 981px) {
    justify-content: center;
    padding: 0 8px;
    .logo {
      display: none;
      width: 104px;

      img {
        max-width: 100%;
      }
    }
    .userInteractions {
      gap: 0;
    }
  }

  .logo {
    width: 104px;

    img {
      max-width: 100%;
    }
  }

  .menu-mobile {
    background: transparent;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
`;

export const CompanyName = styled.span<{ isMobile?: boolean }>`
  font-weight: ${props => (props.isMobile ? '800' : '500')};
  font-size: ${props => (props.isMobile ? '8px' : '18px')};
  color: ${props => (props.theme.title === 'light' ? '#6C7293' : '#FFFFFF')};
  ${props =>
    props.isMobile &&
    css`
      margin-top: -8px;
    `}
  text-align: center;
`;

export const UserInteractions = styled.div`
  display: flex;
  align-items: center;
  .userBg {
    width: 45px;
    height: 40px;
    background-color: ${props =>
      props.theme.title === 'light' ? '#D7F9EF' : '#1C3238'};
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  img {
    max-width: 20px;
    object-fit: cover;
  }
  .logOutBx {
    position: relative;

    @media screen and (max-width: 660px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      gap: 4px;
    }
  }
`;

export const UserInfo = styled.div`
  display: grid;
  grid-template-areas:
    'pBox pBox pBox pBox'
    'envType envType version version';
  gap: 2px;

  p {
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    color: ${props => (props.theme.title === 'light' ? '#959cb6' : '#EEF0F8')};
    grid-area: pBox;

    .userName {
      font-size: 12px;
      font-weight: 500;
      color: ${props => (props.theme.title === 'light' ? '#6C7293' : '#fff')};
    }
  }
  .version {
    color: #6c7293;
    font-size: 12px;
    padding-left: 10px;
    grid-area: version;
  }
`;

export const EnvironmentType = styled.span<{ environment_type: string }>`
  width: 100%;
  max-width: 138px;
  padding: 2px 10px;
  text-align: center;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 11.5px;
  user-select: none;
  grid-area: envType;
  background-color: ${({ theme, environment_type }) => {
    switch (environment_type) {
      case 'desenvolvimento':
        return theme.colors.environmentTypes.dev.bg;
      case 'homologação':
        return theme.colors.environmentTypes.homol.bg;
      case 'produção':
        return theme.colors.environmentTypes.prod.bg;
      default:
        return theme.title === 'light' ? '#EFF2F5' : '#4C4C66';
    }
  }};
  color: ${({ theme, environment_type }) => {
    switch (environment_type) {
      case 'desenvolvimento':
        return theme.colors.environmentTypes.dev.color;
      case 'homologação':
        return theme.colors.environmentTypes.homol.color;
      case 'produção':
        return theme.colors.environmentTypes.prod.color;
      default:
        return '#B5B5C3';
    }
  }};
`;

export const BoxOptions = styled.div`
  display: block;
  width: 160px;
  height: auto;
  background: rgb(255, 255, 255);
  position: absolute;
  right: -18px;
  top: calc(100% + 16px);
  border-radius: 4px;
  filter: drop-shadow(rgba(70, 78, 95, 0.32) 0px 0px 8px);
  padding: 12px 0px;
  z-index: 120;
  &::after {
    position: absolute;
    top: -16px;
    right: 10px;
    content: '';
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent rgb(255, 255, 255);
    border-image: initial;
  }

  button {
    font-size: 16px;
    color: #959cb6;
    padding: 6px 16px !important;
    width: 100%;
    &:hover {
      background-color: #f2f2f2;
    }
    span {
      font-weight: 400 !important;
      display: flex;
      flex-direction: initial;
      align-items: center;
      justify-content: space-between;
      .arrow {
        display: flex;
        align-items: center;
      }
    }
  }
`;
